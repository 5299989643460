<template>
  <div class="container"  id="my-container">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-12 col-md-8 col-lg-6 col-xl-5" style="margin-top: 10%;">
        <div class="card shadow-2-strong" style="border-radius: 1rem;">
          <div class="card-body p-5">
            <h3 class="mb-3  text-center">Sign in</h3>
            <div data-mdb-input-init class="form-outline mb-4">
              <label class="form-label" for="typeEmailX-2">Full Name</label>
              <input type="text" id="typeEmailX-2" class="form-control form-control-sm" v-model="full_name"/>
              <div v-if="errors.full_name">
                <span class="text-danger">{{ errors.full_name[0] }}</span>
              </div>
            </div>

            <div data-mdb-input-init class="form-outline mb-4">
              <label class="form-label" for="typePasswordX-2">Password</label>
              <input type="password" id="typePasswordX-2" class="form-control form-control-sm" v-model="password"/>
              <div v-if="errors.password">
                <span class="text-danger">{{ errors.password[0] }}</span>
              </div>
            </div>
            <button data-mdb-button-init data-mdb-ripple-init :disabled="isLoading" class="btn btn-info btn-sm btn-block" @click="login" type="button">Login</button>
            <div class="row justify-content-center">
              <div class="col-4">
                <pulse-loader 
                  :loading="isLoading" 
                  :color="color"
                  v-if="isLoading" 
                />
              </div>
            </div>
            <hr class="my-4">
            <span class="text-danger">{{ unautorized }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ControlSidebar></ControlSidebar>
</template>

<script>
import auth from '../../services/auth';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    PulseLoader
  },
  data() {
    return {
      full_name: '',
      password: '',
      isLoading: false,
      color: '#40aac7',
      errors: '',
      unautorized: ''
    };
  },
  methods: {
    ...mapActions(['updateToken','updateUser']),
    async login() {
      this.isLoading = true
      try {
        const response = await auth.login(this.full_name, this.password);
        this.isLoading = false
        // localStorage.setItem('token', response.data.token);
        this.updateToken(response.data.token)
        this.updateUser(response.data.user)
        this.$router.push('/');
      } catch (error) {
        if(error.response.status === 422){
          this.isLoading = false
          this.errors = error.response.data.errors
        }

        if(error.response.status === 401){
          this.isLoading = false
          this.unautorized = error.response.data.error
        }
      }
    },
  },
};
</script>

<style scoped>

</style>