import Api from './Api';

export default {
  async login(full_name, password) {
    const response = await Api().post('login', {
      full_name: full_name,
      password: password,
    });
    return response;
  },

  async logout() {
    await Api().post('logout');
  },

  async getUser() {
    const response = await Api().get('login-user');
    return response.data;
  },

  async getUserBranch(){
    const response = await Api.get('get-user-branchId');
    return response;
  }
};