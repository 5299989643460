<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card" style="margin-bottom: 5%;">
                    <!-- <div class="card-header">
                        <div class="row">
                            <div class="col-10">
                                <span style="font-size: 18px;">Approved Loan</span>
                            </div>
                            <RouterLink to="/approved-loan">
                                <button class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                            </RouterLink>
                        </div>
                    </div> -->
                    <pulse-loader 
                        :loading="isLoading" 
                        :color="color"
                        v-if="isLoading" 
                        style="margin-top: 50px; margin-left: 40%;"
                    />
                    <div class="card-body" id="table-content" v-else>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-9">
                                    <img src="../../assets/img/depima_logo.png" width="150"/>
                                </div>
                                <div class="col-3 mt-3">
                                    <span style="font-weight: 600; color: #1d2363;">DEPIMA TECHNOLOGIES</span><br>
                                    <span>Address: SINZA, DAR ES SALAAM</span><br>
                                    <span>Email: dmaleo@depima.co.tz</span><br>
                                    <span>Mobile: 0683 450 285</span><br>
                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <div class="container-fluid">
                                <div class="col-12">
                                    <table  class="table table-hover table-bordered table-sm table-responsive-sm">
                                        <tbody>
                                            <tr>
                                                <td colspan="3" align="center"><span style="font-weight: 600;">Member Informations</span></td>
                                            </tr>
                                            <tr>
                                                <th>First Name</th>
                                                <th>Middle Name</th>
                                                <th>Last Name</th>
                                            </tr>
                                            <tr>
                                                <td>{{ data.member_first_name }}</td>
                                                <td>{{ data.member_middle_name }}</td>
                                                <td>{{ data.member_last_name }}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="3"></td>
                                            </tr>
                                            <tr>
                                                <th>Nida Number</th>
                                                <th>Birth Date</th>
                                                <th>Address</th>
                                            </tr>
                                            <tr>
                                                <td>{{ data.member_nida }}</td>
                                                <td>{{ data.member_dob }}</td>
                                                <td>{{ data.member_address }}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" align="center"><span style="font-weight: 600;">Guarantor Informations</span></td>
                                            </tr>
                                            <tr>
                                                <th>First Name</th>
                                                <th>Middle Name</th>
                                                <th>Last Name</th>
                                            </tr>
                                            <tr>
                                                <td>{{ data.referee_first_name }}</td>
                                                <td>{{ data.referee_middle_name }}</td>
                                                <td>{{ data.referee_last_name }}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="3"></td>
                                            </tr>
                                            <tr>
                                                <th>Mobile Number</th>
                                                <th>Nida Number</th>
                                                <th>Address</th>
                                            </tr>
                                            <tr>
                                                <td>{{ data.referee_phone }}</td>
                                                <td>{{ data.referee_nida }}</td>
                                                <td>{{ data.refee_address }}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" align="center"><span style="font-weight: 600;">Loan Plan Informations</span></td>
                                            </tr>
                                            <tr>
                                                <th>Loan Plan</th>
                                                <th>Recovery Type</th>
                                                <th>Plan Amount</th>
                                            </tr>
                                            <tr>
                                                <td>{{ data.loan_plan_name }}</td>
                                                <td>{{ data.recovery_type }}</td>
                                                <td>{{ formatNumber(data.plan_amount) }}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="3"></td>
                                            </tr>
                                            <tr>
                                                <th>Number fo Payment</th>
                                                <td colspan="2">{{ data.number_of_payment }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                       
                        <div class="row justify-content-center">
                            <div class="col-6">
                                <span style="font-weight: 600;">Payment Schedules</span>
                            </div>

                            <div class="container-fluid">
                                <div class="col-12 mt-2">
                                    <table id="example1" class="table table-hover table-bordered table-striped table-sm table-responsive-sm">
                                        <thead>
                                            <tr>
                                                <td>Date</td>
                                                <td>Inretest</td>
                                                <td>EMI</td>
                                                <td>Status</td>
                                                <td>Balance</td>
                                            </tr>
                                        </thead>
            
                                        <tbody>
                                            <tr v-for="(schedule, i) in schedules" :key="i">
                                                <td>{{ schedule.schedule_date }}</td>
                                                <td>{{ formatNumber(schedule.interest) }}</td>
                                                <td>{{ formatNumber(schedule.emi) }}</td>
                                                <td v-if="schedule.status == 'Paid'"><span class="badge badge-success">{{ schedule.status }}</span></td>
                                                <td v-else><span class="badge badge-danger">{{ schedule.status }}</span></td>
                                                <td>{{ formatNumber(schedule.remaining_balance) }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="col-4 mb-3" v-if="isPrinting">
                                    <button class="btn btn-info btn-sm hide-on-pdf" disabled><i class="fas fa-print"></i> Printing...</button>
                                </div>
                                <div class="col-4 mb-3" v-else>
                                    <button class="btn btn-info btn-sm hide-on-pdf" @click="printPdf"><i class="fas fa-print"></i> Print</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Api from '../../services/Api';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export default{
    components: {
        PulseLoader
    },

    data(){
        return{

            data: {
                id: this.$route.params.id,
                number_of_payment: '',
                member_full_name: '',
                member_first_name: '',
                member_middle_name: '',
                member_last_name: '',
                member_phone: '',
                member_email: '',
                member_nida: '',
                member_dob: '',
                member_address: '',
                referee_full_name: '',
                referee_first_name: '',
                referee_middle_name: '',
                referee_last_name: '',
                referee_phone: '',
                refee_address: '',
                referee_nida: '',
                loan_plan_name: '',
                plan_amount: '',
                recovery_type: ''
            },
            isLoading: false,
            isPrinting: false,
            schedules: [],
            color: '#40aac7',
        }
    },

    mounted(){
        this.fetchLoan();
    },

    methods: {
        async fetchLoan(){
            this.isLoading = true
            await Api().get(`view-approved-loan/${this.data.id}`)
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.schedules = res.data
                    this.data.number_of_payment = res.data[0].loans.number_of_payment
                    this.data.member_full_name = res.data[0].members.first_name+' '+res.data[0].members.middle_name+' '+res.data[0].members.last_name
                    this.data.member_phone = res.data[0].members.phone
                    this.data.member_email = res.data[0].members.email
                    this.data.member_nida = res.data[0].members.nida_no
                    this.data.member_dob = res.data[0].members.dob
                    this.data.member_address = res.data[0].members.address
                    this.data.referee_full_name = res.data[0].members.referee_fname+' '+res.data[0].members.referee_middle_name+' '+res.data[0].members.referee_lname
                    this.data.referee_first_name = res.data[0].members.referee_fname
                    this.data.referee_middle_name = res.data[0].members.referee_middle_name
                    this.data.referee_last_name = res.data[0].members.referee_lname
                    this.data.refee_address = res.data[0].members.referee_address
                    this.data.member_first_name = res.data[0].members.first_name
                    this.data.member_middle_name = res.data[0].members.middle_name
                    this.data.member_last_name = res.data[0].members.last_name
                    this.data.referee_phone = res.data[0].members.referee_phone
                    this.data.referee_nida = res.data[0].members.referee_nida_no
                    this.data.loan_plan_name = res.data[0].loan_plans.plan_name
                    this.data.plan_amount = res.data[0].loan_plans.plan_amount
                    this.data.recovery_type = res.data[0].loan_plans.recovery_type
                }
            }).catch(error =>{
                this.isLoading = false
                console.error('Failed to get loan plan information',error)
            })
        },

        async printPdf(){
            // Hide buttons
            this.isPrinting = true
            const buttons = document.querySelectorAll('.hide-on-pdf');
            buttons.forEach(button => button.style.display = 'none');

            const element = document.getElementById('table-content');
            const canvas = await html2canvas(element);
            const imgData = canvas.toDataURL('image/png');
            
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'pt',
                format: 'a4'
            });

            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
            
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      
            // Convert the PDF to a Blob to create a URL for preview
            const pdfBlob = pdf.output('blob');
            const pdfUrl = URL.createObjectURL(pdfBlob);

            // Open the PDF in a new window or tab for preview
            const pdfWindow = window.open(pdfUrl, '_blank');

            if (pdfWindow) {
                this.isPrinting = false
                // Automatically print the PDF after opening the preview
                pdfWindow.addEventListener('load', () => {
                // pdfWindow.print();
                });
            } else {
                this.isLoading = false
                // If the window couldn't be opened (e.g., due to pop-up blockers)
                alert('Please allow pop-ups to preview and print the PDF.');
            }

            // Show buttons again
            buttons.forEach(button => button.style.display = 'inline-block');
        },


        formatNumber(value){
            return new Intl.NumberFormat().format(value);
        }
    }
}
</script>

<style scoped>
    #example1{
        border-collapse: collapse; 
        border: 1px solid #839E99;
        background: #f1f8ee;  
        color: #033;
    }

    .size{
    height: 34px;
    border-color: rgb(243, 246, 247);
    }

    .span-style{
        margin-left: 2px;
        font-weight: 500;
    }

    #container-border{
        border-style: solid;
        border-width: 1px;
        border-color: rgb(212, 217, 221);
    }
</style>