<template>
  <!-- <div class="row justify-content-center">
    <div class="col-7">
        <div class="card">
            <div class="card-body">
                <div class="row justify-content-center">
                    <div class="col-3 mb-2">
                        <span style="font-weight: 600;">Short Keys</span>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-12">
                        <button class="btn btn-default btn-lg mr-4">Members <i class="fas fa-user-plus"></i></button>
                        <button class="btn btn-default btn-lg mr-4">Plan <i class="fas fa-transgender-alt"></i></button>
                        <button class="btn btn-default btn-lg mr-4">EMI Collection <i class="fas fa-tag"></i></button>
                        <button class="btn btn-default btn-lg">Loan <i class="fas fa-wallet"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div> -->
  <ContentLoader v-if="isLoading"/>
    <div class="row" v-else style="margin-bottom: 2%;">
        <div class="col-md-3 col-sm-6 col-12">
            <div class="info-box">
                <span class="info-box-icon bg-info" style="background-color: #171a3b !important"><i class="fab fa-wolf-pack-battalion"></i></span>
                <div class="info-box-content">
                <span class="info-box-text">TOTAL BALANCE</span>
                <span class="info-box-number">{{ formatNumber(data.total_balance) }}</span>
                </div>
            </div>
        </div>

        <div class="col-md-3 col-sm-6 col-12">
            <div class="info-box">
            <span class="info-box-icon bg-success"><i class="fas fa-wallet"></i></span>
            <div class="info-box-content">
            <span class="info-box-text">CASH BALANCE</span>
            <span class="info-box-number">{{ formatNumber(data.cash_balance) }}</span>
            </div>
            <!-- /.info-box-content -->
            </div>
            <!-- /.info-box -->
        </div>

        <div class="col-md-3 col-sm-6 col-12">
            <div class="info-box">
            <span class="info-box-icon bg-danger"><i class="fas fa-university"></i></span>
            <div class="info-box-content">
            <span class="info-box-text">BANK BALANCE</span>
            <span class="info-box-number">{{ formatNumber(data.bank_balance) }}</span>
            </div>
            <!-- /.info-box-content -->
            </div>
            <!-- /.info-box -->
        
        </div>

        <div class="col-md-3 col-sm-6 col-12">
            <div class="info-box">
            <span class="info-box-icon bg-warning" style="background-color: #eb1aeb !important;"><i class="fas fa-weight-hanging" style="color: #FFFFFF"></i></span>

            <div class="info-box-content">
                <span class="info-box-text">TOTAL INTEREST</span>
                <span class="info-box-number">{{ formatNumber(data.total_interest) }}</span>
            </div>
            <!-- /.info-box-content -->
            </div>
            <!-- /.info-box -->
        </div>

        <div class="col-lg-3 col-6">
            <!-- small box -->
            <div class="small-box bg-info" style="background-color: #171a3b !important">
            <div class="inner">
                <h3>{{ formatNumber(data.total_collection) }}</h3>
                <p>Total Collection Amount</p>
            </div>
            <div class="icon">
                <i class="ion ion-bag"></i>
            </div>
            <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
            </div>
        </div>

        <div class="col-lg-3 col-6">
            <!-- small box -->
            <div class="small-box bg-success">
            <div class="inner">
                <h3>{{ formatNumber(data.total_disbursement) }}<sup style="font-size: 20px"></sup></h3>
                <p>Total Disbusment Amount</p>
            </div>
            <div class="icon">
                <i class="ion ion-stats-bars"></i>
            </div>
            <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
            </div>
        </div>

        <div class="col-lg-3 col-6">
            <!-- small box -->
            <div class="small-box bg-danger">
            <div class="inner">
                <h3>{{ formatNumber(data.total_outstanding) }}</h3>
                <p>Total Outstanding Amount</p>
            </div>
            <div class="icon">
                <i class="ion ion-pie-graph"></i>
            </div>
            <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
            </div>
        </div>

        <div class="col-lg-3 col-6">
            <!-- small box -->
            <div class="small-box bg-warning" style="background-color: #eb1aeb !important;">
            <div class="inner">
                <h3 style="color: #FFFFFF;">{{ data.due_loans }}</h3>
                <p style="color: #FFFFFF;">Due Loans</p>
            </div>
            <div class="icon">
                <i class="ion ion-person-add"></i>
            </div>
            <RouterLink to="/emi-collection" class="small-box-footer" style="color: #FFFFFF !important;">
                More info <i class="fas fa-arrow-circle-right"></i>
            </RouterLink>
            <!-- <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a> -->
            </div>
        </div>

        <div class="col-lg-3 col-6">
            <!-- Widget: user widget style 2 -->
            <div class="card card-widget widget-user-2">
                <!-- Add the bg color to the header using any of the bg-* classes -->
                <div class="card-header bg-success">
                    <span>Collection</span>
                    <h6>Approved In Amount</h6>
                </div>
                <div class="card-footer p-0">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <div class="p-2">
                            Today <span class="float-right badge bg-primary">31</span>
                        </div>
                    </li>
                    <li class="nav-item">
                        <div class="p-2">
                            Weekly <span class="float-right badge bg-info">5</span>
                        </div>
                    </li>
                    <li class="nav-item">
                        <div class="p-2">
                            Monthly <span class="float-right badge bg-success">12</span>
                        </div>
                    </li>
                    <li class="nav-item">
                        <div class="p-2">
                            Total <span class="float-right badge bg-danger">842</span>
                        </div>
                    </li>
                </ul>
                </div>
            </div>
            <!-- /.widget-user -->
        </div>

        <div class="col-lg-3 col-6">
            <!-- Widget: user widget style 2 -->
            <div class="card card-widget widget-user-2">
                <!-- Add the bg color to the header using any of the bg-* classes -->
                <div class="card-header bg-danger">
                    <span>Collection</span>
                    <h6>Pending In Amount</h6>
                </div>
                <div class="card-footer p-0">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <div class="p-2">
                            Today <span class="float-right badge bg-primary">31</span>
                        </div>
                    </li>
                    <li class="nav-item">
                        <div class="p-2">
                            Weekly <span class="float-right badge bg-info">5</span>
                        </div>
                    </li>
                    <li class="nav-item">
                    <div class="p-2">
                        Monthly <span class="float-right badge bg-success">12</span>
                    </div>
                    </li>
                    <li class="nav-item">
                        <div class="p-2">
                            Total <span class="float-right badge bg-danger">842</span>
                        </div>
                    </li>
                </ul>
                </div>
            </div>
            <!-- /.widget-user -->
        </div>

        <div class="col-lg-3 col-6">
            <!-- Widget: user widget style 2 -->
            <div class="card card-widget widget-user-2">
                <!-- Add the bg color to the header using any of the bg-* classes -->
                <div class="card-header bg-success">
                    <span>Disbursement</span>
                    <h6>Approved In Amount</h6>
                </div>
                <div class="card-footer p-0">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <div class="p-2">
                            Today <span class="float-right badge bg-primary">31</span>
                        </div>
                    </li>
                    <li class="nav-item">
                        <div class="p-2">
                            Weekly <span class="float-right badge bg-info">5</span>
                        </div>
                    </li>
                    <li class="nav-item">
                        <div class="p-2">
                            Monthly <span class="float-right badge bg-success">12</span>
                        </div>
                    </li>
                    <li class="nav-item">
                        <div class="p-2">
                            Total <span class="float-right badge bg-danger">842</span>
                        </div>
                    </li>
                </ul>
                </div>
            </div>
            <!-- /.widget-user -->
        </div>

        <div class="col-lg-3 col-6">
            <!-- Widget: user widget style 2 -->
            <div class="card card-widget widget-user-2">
                <!-- Add the bg color to the header using any of the bg-* classes -->
                <div class="card-header bg-danger">
                    <span>Disbursement</span>
                    <h6>Pending In Amount</h6>
                </div>
                <div class="card-footer p-0">
                    <ul class="nav flex-column">
                        <li class="nav-item">
                            <div class="p-2">
                                Today <span class="float-right badge bg-primary">31</span>
                            </div>
                        </li>
                        <li class="nav-item">
                            <div class="p-2">
                                Weekly <span class="float-right badge bg-info">5</span>
                            </div>
                        </li>
                        <li class="nav-item">
                            <div class="p-2">
                                Monthly <span class="float-right badge bg-success">12</span>
                            </div>
                        </li>
                        <li class="nav-item">
                            <div class="p-2">
                                Total <span class="float-right badge bg-danger">842</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- /.widget-user -->
        </div>


        <div class="col-lg-6 col-6">
            <div class="card" style="margin-bottom: 10%;">
                <div class="card-header">
                    <h6>Recent Collection</h6>
                </div>
                <div class="card-body">
                    <table class="table table-hover table-bordered table-striped table-sm table-responsive-sm">
                        <thead>
                            <tr>
                                <th>Sr.No</th>
                                <th>LoanID</th>
                                <th>Cust Name</th>
                                <th>Pay Date</th>
                                <th>Amount</th>
                                <th>Received</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>01</td>
                                <td>56786</td>
                                <td>Deogratius Maleo</td>
                                <td>Jul 05, 2024</td>
                                <td>200,000</td>
                                <td>Esther</td>
                            </tr>
                            <tr>
                                <td>03</td>
                                <td>5678</td>
                                <td>Onesmo</td>
                                <td>Jul 02, 2024</td>
                                <td>300,000</td>
                                <td>Elly</td>
                            </tr>
                            <tr>
                                <td>05</td>
                                <td>9087</td>
                                <td>John</td>
                                <td>Jul 01, 2024</td>
                                <td>500,000</td>
                                <td>Elly</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="col-lg-6 col-6">
            <div class="card" style="margin-bottom: 10%;">
                <div class="card-header">
                    <h6>Recent Members</h6>
                </div>
                <div class="card-body">
                    <table class="table table-hover table-bordered table-striped table-sm table-responsive-sm">
                        <thead>
                            <tr>
                                <th>Sr.No</th>
                                <th>Cust Name</th>
                                <th>Cust Code</th>
                                <th>Mobile</th>
                                <th>Address</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>01</td>
                                <td>Daud Juma</td>
                                <td>MEM6</td>
                                <td>0714 412 755</td>
                                <td>Sinza</td>
                            </tr>
                            <tr>
                                <td>02</td>
                                <td>Mniger Maleo</td>
                                <td>MEM7</td>
                                <td>0714 412 755</td>
                                <td>Moshi</td>
                            </tr>
                            <tr>
                                <td>03</td>
                                <td>Cosmas Minja</td>
                                <td>MEM8</td>
                                <td>0714 412 755</td>
                                <td>K/Nyama</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from '../../services/Api';
import ContentLoader from '../../components/ContentLoader.vue';

export default {
  components: {
    ContentLoader
  },

  data() {
    return {
        data: {
            total_balance: 0.0,
            cash_balance: 0.0,
            bank_balance: 0.0,
            total_interest: 0.0,
            total_collection: 0.0,
            total_disbursement: 0.0,
            due_loans: 0.0,
            total_outstanding: 0.0
        },
        isLoading: false
    };
  },

  mounted(){
    this.getTotals();
  },

  methods: {
    async getTotals(){
        this.isLoading = true
        await Api().get('dashboard-totals')
        .then(res =>{
            if(res.status === 200){
                this.isLoading = false
                this.data.total_balance = res.data.total_balance
                this.data.cash_balance = res.data.cash_balance
                this.data.bank_balance = res.data.bank_balance
                this.data.total_interest = res.data.total_interest
                this.data.total_collection = res.data.total_collection
                this.data.total_disbursement = res.data.totla_disbursiment
                this.data.due_loans = res.data.due_loans
                this.data.total_outstanding = res.data.total_outstanding
            }
        }).catch(error =>{
            if(error){
                this.isLoading = false
                console.log('Failed to get informations')
            }
        })
    },

    formatNumber(value){
        return new Intl.NumberFormat().format(value);
    }
  }
};
</script>

<style>
    .rt{
        background-color: #eb1aeb;
    }
</style>