<template>
    <div class="container-fluid">
        <ContentLoader v-if="isLoading"/>
        <div class="row" v-else>
            <div class="col-md-12">
                <div class="card" style="margin-bottom: 5%;">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-10">
                                <span style="font-size: 18px;">EMI Collection</span>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <!-- <pulse-loader 
                            :loading="isLoading" 
                            :color="color"
                            v-if="isLoading" 
                            style="margin-top: 50px; margin-left: 40%;"
                        /> -->
                        <div class="row">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-9"></div>
                                    <div class="col-3">
                                        <!-- <span>Total Current Due: </span> -->
                                    </div>
                                </div>
                                <table id="example1" class="table table-hover table-bordered table-striped table-sm table-responsive-sm">
                                    <thead>
                                        <tr>
                                            <td>MEMBER CODE</td>
                                            <td>MEMBER NAME</td>
                                            <td>MOBILE</td>
                                            <td>LOAN CODE</td>
                                            <td>EMI DATE</td>
                                            <td>RECOVERY TYPE</td>
                                            <td>EMI</td>
                                            <td>STATUS</td>
                                            <td>ACTION</td>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr v-for="(loan, i) in loans" :key="i">
                                            <td>{{ loan.members.memberId }}</td>
                                            <td>
                                                <RouterLink :to="{name: 'individual-member', params:{id: loan.members.id}}">
                                                    <span style="color: #444444;">{{ loan.members.first_name }} {{ loan.members.middle_name }} {{ loan.members.last_name }}</span>
                                                </RouterLink>
                                            </td>
                                            <td>{{ loan.members.phone }}</td>
                                            <td>{{ loan.loans.loanId }}</td>
                                            <td>{{ loan.schedule_date }}</td>
                                            <td>{{ loan.loan_plans.recovery_type }}</td>
                                            <td>{{ formatNumber(loan.emi) }}</td>
                                            <td><span class="badge badge-danger">{{ loan.status }}</span></td>
                                            <td>
                                                <RouterLink :to="{name: 'payment-collection',params:{id: loan.id}}">
                                                    <button class="btn btn-success btn-sm">Collect Payment</button>
                                                </RouterLink>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Api from '../../services/Api';
import ContentLoader from '../../components/ContentLoader.vue';

export default{
    components: {
        // PulseLoader
        ContentLoader
    },

    data(){
        return{
            isLoading: false,
            loans: [],
            color: '#40aac7',
        }
    },

    mounted(){
        this.fetchLoan();
    },

    methods: {
        async fetchLoan(){
            this.isLoading = true
            await Api().get('emi-collection')
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.loans = res.data
                }
            }).catch(error =>{
                this.isLoading = false
                console.error('Failed to get loan plan information',error)
            })
        },

        formatNumber(value){
            return new Intl.NumberFormat().format(value);
        }
    }
}
</script>

<style scoped>
 #example1{
    border-collapse: collapse; 
    border: 1px solid #839E99;
    background: #f1f8ee;  
    color: #033;
}
</style>