<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card" style="margin-bottom: 5%;">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-md-10">
                               <span style="font-weight: 500;">Approve Loan Disbursment</span>
                            </div>
                            <div class="col-md-2">
                                <RouterLink to="/pending-loan-lists">
                                    <button class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                    <pulse-loader 
                        :loading="isLoading" 
                        :color="color"
                        v-if="isLoading" 
                        style="margin-top: 50px; margin-left: 40%;"
                    />
                    <div class="card-body" v-else>
                        <div class="container-fluid " style="border-style: solid; border-width: 1px; border-color: aliceblue;">
                            <div class="row mt-2">
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Mamber Full Name*</span>
                                        <input type="text" class="form-control size mt-2" v-model="data.member_full_name"/>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Phone No*</span>
                                        <input type="text" class="form-control size mt-2" v-model="data.member_phone"/>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Email*</span>
                                        <input type="text" class="form-control size mt-2" v-model="data.member_email"/>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">NIDA No*</span>
                                        <input type="text" class="form-control size mt-2" v-model="data.member_nida"/>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">D.O.B*</span>
                                        <input type="text" class="form-control size mt-2" v-model="data.member_dob"/>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Address*</span>
                                        <input type="text" class="form-control size mt-2" v-model="data.member_address"/>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Collateral*</span>
                                        <input type="text" class="form-control size mt-2" v-model="data.collateral"/>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Referee Full Name*</span>
                                        <input type="text" class="form-control size mt-2" v-model="data.referee_full_name"/>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Referee Phone No*</span>
                                        <input type="text" class="form-control size mt-2" v-model="data.referee_phone"/>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Referee NIDA No*</span>
                                        <input type="text" class="form-control size mt-2" v-model="data.referee_nida" />
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Plan Name*</span>
                                        <input type="text" class="form-control size mt-2" v-model="data.loan_plan_name"/>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Recovery Type*</span>
                                        <input type="text" class="form-control size mt-2" v-model="data.recovery_type"/>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Plan Amount*</span>
                                        <input type="text" class="form-control size mt-2" v-model="data.plan_amount"/>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Number of Payment*</span>
                                        <input type="text" class="form-control size mt-2" v-model="data.no_of_payment"/>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Type of Interest*</span>
                                        <input type="text" class="form-control size mt-2" v-model="data.type_of_interest"/>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Penalty Rate*</span>
                                        <input type="text" class="form-control size mt-2" v-model="data.penalty_rate"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="container-fluid" style="border-style: solid; border-width: 1px; border-color: aliceblue;">
                            <div class="row mt-2">
                                <div class="col">
                                    <h5>Disbursment Informations</h5>
                                </div>
                            </div>

                            <div class="row mt-2">
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Disbursment Date*</span><br>
                                        <div class="mt-2">
                                            <date-picker v-model:value="approveData.disbursment_date" value-type="YYYY-MM-DD" />
                                            <div v-if="errors.disbursment_date">
                                                <span class="text-danger">{{ errors.disbursment_date[0] }}*</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">EMI Start Date*</span><br>
                                        <div class="mt-2">
                                            <date-picker v-model:value="approveData.emi_date" value-type="YYYY-MM-DD" />
                                            <div v-if="errors.emi_date">
                                                <span class="text-danger">{{ errors.emi_date[0] }}*</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Larger Account*</span>
                                        <select class="form-control size mt-2" v-model="approveData.larger_accId">
                                            <option v-for="(account, i) in accounts" :key="i" :value="account.id">{{ account.account_name }} - {{ account.account_no }}</option>
                                        </select>
                                        <div v-if="errors.larger_accId">
                                            <span class="text-danger">{{ errors.larger_accId }}*</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-2">
                                <div class="col-4" v-if="isApprove">
                                    <div class="btn btn-info btn-sm disabled" type="button">Approve...</div>
                                </div>
                                <div class="col-4" v-else>
                                    <div class="btn btn-info btn-sm" @click="approveLoan" type="button">Approve</div>
                                </div>
                                <pulse-loader 
                                    :loading="isApprove" 
                                    :color="color"
                                    v-if="isApprove" 
                                />
                                <div class="col-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Api from '../../services/Api';
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import Swal from 'sweetalert2';

export default{
    components: {
        PulseLoader,
        DatePicker
    },

    data(){
        return{
            data: {
               member_full_name: '',
               member_email: '',
               member_phone: '',
               member_nida: '',
               member_dob: '',
               member_address: '',
               collateral: '',
               referee_full_name: '',
               referee_phone: '',
               referee_nida: '',
               loan_plan_name: '',
               recovery_type: '',
               plan_amount: '',
               no_of_payment: '',
               type_of_interest: '',
               penalty_rate: 0,
            },

            approveData: {
                memberId: '',
                loanId: '',
                loan_planId: '',
                disbursment_date: '',
                emi_date: '',
                larger_accId: '',
                recovery_type_number: '',
                recovery_type: '',
                type_of_interest: '',
                annual_interest_rate: '',
                principal: '',
                tenure: '',
                no_of_payment: '',
                penalty_rate: 0,
            },

            isLoading: false,
            isApprove: false,
            color: '#40aac7',
            errors: '',
            id: this.$route.params.id,
            accounts: []
        }
    },

    mounted(){
      this.fetchLoan();
      this.getAccounts();
    },

    methods: {
        async fetchLoan(){
            this.isLoading = true
            await Api().get(`loans/${this.id}`)
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.data.member_full_name = res.data[0].members.first_name +" "+res.data[0].members.middle_name +" "+res.data[0].members.last_name
                    this.data.member_phone = res.data[0].members.phone
                    this.data.member_email = res.data[0].members.email
                    this.data.member_nida = res.data[0].members.nida_no
                    this.data.member_dob = res.data[0].members.dob
                    this.data.member_address = res.data[0].members.address
                    this.data.collateral = res.data[0].members.collateral
                    this.data.referee_full_name = res.data[0].members.referee_fname+" "+res.data[0].members.referee_middle_name+" "+res.data[0].members.referee_lname
                    this.data.referee_phone = res.data[0].members.referee_phone
                    this.data.referee_nida = res.data[0].members.referee_nida_no
                    this.data.loan_plan_name = res.data[0].loan_plans.plan_name
                    this.data.recovery_type = res.data[0].loan_plans.recovery_type
                    this.data.plan_amount = res.data[0].loan_plans.plan_amount
                    this.data.no_of_payment = res.data[0].loan_plans.number_of_payment
                    this.data.type_of_interest = res.data[0].loan_plans.type_of_interest

                    this.approveData.memberId = res.data[0].memberId
                    this.approveData.annual_interest_rate = res.data[0].loan_plans.interest_rate
                    this.approveData.loanId = res.data[0].id
                    this.approveData.loan_planId = res.data[0].loan_planId
                    this.approveData.principal = this.data.plan_amount
                    this.approveData.recovery_type_number = res.data[0].loan_plans.recovery_type_number
                    this.approveData.tenure = this.data.no_of_payment
                    this.approveData.recovery_type = this.data.recovery_type
                    this.approveData.type_of_interest = res.data[0].loan_plans.type_of_interest
                    this.approveData.no_of_payment = this.data.no_of_payment
                }
            }).catch(error =>{
                this.isLoading = false
                console.error('Failed to get loan plan information',error)
            })
        },

        async getAccounts(){
        this.isLoading = true
        await Api().get('bank-accounts')
        .then(res => {
            if(res.status === 200){
                this.isLoading = false
                this.accounts = res.data
            }
        }).catch(error =>{
            if(error){
                this.isLoading = false
                return console.log('Failed to get account informations.')
            }
        })
       },

        async approveLoan(){
            this.isApprove = true
            if(this.approveData.larger_accId === ''){
                this.isApprove = false
                Swal.fire({
                icon: "error",
                title: "Error",
                text: 'Larger account is required!',
                });
            }
            await Api().post('scheduals',this.approveData)
            .then(res =>{
                if(res.status === 200){
                    this.isApprove = false
                    Swal.fire({
                        title: "Good job!",
                        text: `${res.data.message}`,
                        icon: "success"
                    });
                    this.$router.push('/pending-loan-lists')
                }else{
                    this.isApprove = false
                    Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: `${res.data.error}`,
                    });
                }
            }).catch(error =>{
                if(error.response.status === 422){
                    this.isApprove = false
                    this.errors = error.response.data.errors
                }
            })
        },
    }
}
</script>

<style scoped>
.size{
    height: 34px;
    border-color: aliceblue;
}

.span-style{
    margin-left: 2px;
    font-weight: 500;
}
</style>