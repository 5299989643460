<template>
    <div class="container-fluid">
        <ContentLoader v-if="isLoading"/>
        <div class="row" v-else>
            <div class="col-12">
                <div class="card" style="margin-bottom: 5%;">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-10">
                                <span style="font-size: 18px;">Loan Plan Lists</span>
                            </div>
                            <div class="col-2">
                                <RouterLink to="/loan-lists">
                                    <button class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <!-- <pulse-loader 
                            :loading="isLoading" 
                            :color="color"
                            v-if="isLoading" 
                            style="margin-top: 50px; margin-left: 40%;"
                        /> -->

                        <div class="row">
                            <div class="col-12">
                                <table id="example1" class="table table-hover table-bordered table-striped table-sm table-responsive-sm">
                                    <thead>
                                        <tr>
                                            <td>Plan Name</td>
                                            <td>Plan Type</td>
                                            <td>Amount</td>
                                            <td>Interest Rate</td>
                                            <td>Penalty Rate</td>
                                            <td>Recovery Type</td>
                                            <td>Status</td>
                                            <td>View</td>
                                            <td>Edit</td>
                                            <td>Delete</td>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr v-for="(loan, i) in loansPlan" :key="i">
                                            <td>{{ loan.plan_name }}</td>
                                            <td>{{ loan.plan_type }}</td>
                                            <td>{{ formatNumber(loan.plan_amount) }}</td>
                                            <td>{{ loan.interest_rate }}</td>
                                            <td>{{ loan.penalty_rate }}</td>
                                            <td>{{ loan.recovery_type }}</td>
                                            <td><span class="badge badge-success">{{ loan.status }}</span></td>
                                            <td>
                                                <RouterLink :to="{name: 'view-plan',params:{id: loan.id}}">
                                                    <button class="btn btn-info btn-sm" style="background-color: #1d2363"><i class="fa fa-eye"></i></button>
                                                </RouterLink>
                                            </td>
                                            <td>
                                                <RouterLink :to="{name: 'edit-plan',params:{id: loan.id}}">
                                                    <button class="btn btn-info btn-sm"><i class="fa fa-edit"></i></button>
                                                </RouterLink>
                                            </td>
                                            <td>
                                                <button class="btn btn-danger btn-sm" @click="deleteConfirm(loan.id)"><i class="fa fa-trash"></i></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Api from '../../services/Api';
import Swal from 'sweetalert2';
import ContentLoader from '../../components/ContentLoader.vue';

export default{
    components: {
        // PulseLoader,
        ContentLoader
    },

    data(){
        return{
            isLoading: false,
            loansPlan: [],
            color: '#40aac7',
        }
    },

    mounted(){
        this.fetchLoanPlan();
    },

    methods: {
        async fetchLoanPlan(){
            this.isLoading = true
            await Api().get('loan-plans')
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.loansPlan = res.data
                }
            }).catch(error =>{
                this.isLoading = false
                console.error('Failed to get loan plan information',error)
            })
        },

        async deleteLoanPlan(id){
            this.isLoading = true
            await Api().delete(`loan-plans/${id}`)
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                }
            }).catch(error =>{
                if(error){
                    console.error('Failed to delete loan plan!',error)
                }
            })
        },

        deleteConfirm(id){
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: "btn btn-success",
                    cancelButton: "btn btn-danger"
                },
                buttonsStyling: false
            });

            swalWithBootstrapButtons.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, cancel!",
            reverseButtons: true
            }).then((result) => {
            if (result.isConfirmed) {
                this.deleteLoanPlan(id);
                this.loansPlan.splice(this.deleteIdex, 1)
                swalWithBootstrapButtons.fire({
                title: "Deleted!",
                text: "Loan plan has been deleted.",
                icon: "success"
                });
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire({
                title: "Cancelled",
                text: "Your loan plan is safe",
                icon: "error"
                });
            }
            });
        },

        formatNumber(value){
            return new Intl.NumberFormat().format(value);
        }
    }
}
</script>

<style scoped>
    #example1{
        border-collapse: collapse; 
        border: 1px solid #839E99;
        background: #f1f8ee;  
        color: #033;
    }
</style>