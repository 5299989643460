<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card" style="margin-bottom: 5%;">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-10">
                               <span style="font-weight: 500;">Payment Collection</span>
                            </div>
                            <div class="col-2">
                                <RouterLink to="/emi-collection">
                                    <button class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                    <pulse-loader 
                        :loading="isLoading" 
                        :color="color"
                        v-if="isLoading" 
                        style="margin-top: 50px; margin-left: 40%;"
                    />
                    <div class="card-body" v-else>
                        <div class="row">
                            <div class="col-7">
                                <div class="container-fluid " style="border-style: solid; border-width: 1px; border-color: aliceblue;">
                                    <div class="row mt-2">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <span class="span-style">Amount*</span>
                                                <input type="text" class="form-control size mt-2" v-model="data.amount"/>
                                                <div v-if="errors.amount">
                                                    <span class="text-danger">{{ errors.amount[0] }}*</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <span class="span-style">Receivable Account*</span>
                                                <select class="form-control size mt-2" v-model="data.accountId">
                                                    <option v-for="(account, i) in accounts" :key="i" :value="account.id">{{ account.account_name }} - {{ account.account_no }}</option>
                                                </select>
                                                <div v-if="errors.accountId">
                                                    <span class="text-danger">{{ errors.accountId[0] }}*</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <span class="span-style">Reference Number*</span>
                                                <input type="text" class="form-control size mt-2" v-model="data.ref_no"/>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <span class="span-style">Remark*</span>
                                                <input type="text" class="form-control size mt-2" v-model="data.remark"/>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <span class="span-style">Payment Date*</span><br>
                                                <date-picker v-model:value="data.date" value-type="YYYY-MM-DD" />
                                                <div v-if="errors.bankId">
                                                    <span class="text-danger">{{ errors.bankId[0] }}*</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="row mb-2 mt-2">
                                        <div class="col-4" v-if="updatLoading">
                                            <div class="btn btn-info btn-sm disabled" type="button">Submit</div>
                                        </div>
                                        <div class="col-4" v-else>
                                            <div class="btn btn-info btn-sm" @click="receiveCollection" type="button">Submit</div>
                                        </div>
                                        <pulse-loader 
                                            :loading="updatLoading" 
                                            :color="color"
                                            v-if="updatLoading" 
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="col-5">
                                <div class="container-fluid " style="border-style: solid; border-width: 1px; border-color: aliceblue;">
                                    <!-- Widget: user widget style 2 -->
                                    <div class="card card-widget widget-user-2">
                                        <!-- Add the bg color to the header using any of the bg-* classes -->
                                        <div class="widget-user-header bg-info">
                                            <!-- <div class="widget-user-image">
                                            <img class="img-circle elevation-2"  alt="User Avatar">
                                            </div> -->
                                            <!-- /.widget-user-image -->
                                            <h6 class="widget-user-username">{{ data.full_name }}</h6>
                                            <!-- <h5 class="widget-user-desc">Lead Developer</h5> -->
                                        </div>
                                        <div class="card-footer p-0">
                                            <ul class="nav flex-column">
                                            <li class="nav-item">
                                                <div class="p-2">
                                                    Days Late<span class="float-right badge bg-warning">{{ formatNumber(data.days_late) }}</span>
                                                </div>
                                            </li>
                                            <li class="nav-item">
                                                <div class="p-2">
                                                   Penalty Amount<span class="float-right badge bg-primary">{{ formatNumber(data.penalty_amount) }}</span>
                                                </div>
                                            </li>
                                            <li class="nav-item">
                                                <div class="p-2">
                                                    Disbursment Amount <span class="float-right badge bg-info">{{ formatNumber(data.disbursement_amount) }}</span>
                                                </div>
                                            </li>
                                            <li class="nav-item">
                                                <div class="p-2">
                                                    Paid Amount <span class="float-right badge bg-success">{{ formatNumber(data.amount_paid) }}</span>
                                                </div>
                                            </li>
                                            <li class="nav-item">
                                                <div class="p-2">
                                                    Remain balance <span class="float-right badge bg-danger">{{ formatNumber(data.remain_balance) }}</span>
                                                </div>
                                            </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <!-- /.widget-user -->
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import Api from '../../services/Api';
import Swal from 'sweetalert2';

export default{
    components: {
        PulseLoader,
        DatePicker,
    },

    data(){
        return{
            data: {
              amount: '',
              accountId: '',
              date: '',
              full_name: '',
              phone: '',
              ref_no: '',
              amount_paid: '',
              remain_balance: '',
              member_code: '',
              disbursement_amount: '',
              remark: '',
              id: this.$route.params.id,
              memberId: '',
              penalty_amount: 0,
              days_late: 0,
            },

            isLoading: false,
            updatLoading: false,
            color: '#40aac7',
            errors: '',
            accounts: [],
        }
    },

    mounted(){
        this.getAccounts();
        this.fetchData();
    },

    methods: {

        async fetchData(){
            this.isLoading = true
            await Api().get(`scheduals/${this.data.id}`)
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.data.date = res.data.schedules[0].schedule_date
                    this.data.amount = res.data.schedules[0].emi
                    this.data.full_name = res.data.schedules[0].members.first_name+' '+res.data.schedules[0].members.middle_name+' '+res.data.schedules[0].members.last_name
                    this.data.phone = res.data.schedules[0].members.phone
                    this.data.disbursement_amount = res.data.schedules[0].loan_plans.plan_amount
                    this.data.amount_paid = res.data.schedules[0].loans.amount_paid
                    this.data.remain_balance = res.data.schedules[0].loans.amount_due
                    this.data.member_code = res.data.schedules[0].members.memberId
                    this.data.memberId = res.data.schedules[0].members.id
                    this.data.penalty_amount = res.data.penalty
                    this.data.days_late = res.data.daysLate
                }
            }).catch(error =>{
                if(error){
                    this.isLoading = false
                    return console.log('Failed to get informations.')
                }
            })
        },

       async getAccounts(){
        this.isLoading = true
        await Api().get('bank-accounts')
        .then(res => {
            if(res.status === 200){
                this.isLoading = false
                this.accounts = res.data
            }
        }).catch(error =>{
            if(error){
                this.isLoading = false
                return console.log('Failed to get account informations.')
            }
        })
       },

       async receiveCollection(){
        this.updatLoading = true
        await Api().post(`receive-collection/${this.data.id}`,this.data)
        .then(res =>{
            if(res.status === 200){
                this.updatLoading = false
                Swal.fire({
                    title: "Good job!",
                    text: `${res.data.message}`,
                    icon: "success"
                });
                this.$router.push('/emi-collection')
            }else{
                this.updatLoading = false
                Swal.fire({
                icon: "error",
                title: "Error",
                text: `${res.data.error}`,
                });
            }
        }).catch(error =>{
            if(error.response.status === 422){
                this.updatLoading = false
                this.errors = error.response.data.errors
            }
        })
       },

       formatNumber(value){
            return new Intl.NumberFormat().format(value);
        }
    }
}
</script>

<style>
.size{
    height: 34px;
    border-color: rgb(222, 232, 241);
}

.span-style{
    margin-left: 2px;
    font-weight: 500;
}
</style>