<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card" style="margin-bottom: 5%;">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-10">
                               <span style="font-weight: 500;">New Bank Information</span>
                            </div>
                            <div class="col-2">
                                <RouterLink to="/bank-account-list">
                                    <button class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="container-fluid " style="border-style: solid; border-width: 1px; border-color: aliceblue;">
                            <div class="row mt-2 justify-content-center">
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Bank Name*</span>
                                        <input type="text" class="form-control size mt-2" v-model="data.bank_name"/>
                                        <div v-if="errors.bank_name">
                                        <span class="text-danger">{{ errors.bank_name[0] }}*</span>
                                    </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Bank Code*</span>
                                        <input type="text" class="form-control size mt-2" v-model="data.bank_code" placeholder="Optional"/>
                                    </div>
                                </div>
                            </div>

                            <div class="row justify-content-center">
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Address*</span>
                                        <input type="text" class="form-control size mt-2" v-model="data.address" placeholder="Optional"/>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Email*</span>
                                        <input type="text" class="form-control size mt-2" v-model="data.email" placeholder="Optional"/>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div class="col-2"></div>
                                <div class="col-4" v-if="isLoading">
                                    <div class="btn btn-info btn-sm disabled" type="button">Submit</div>
                                </div>
                                <div class="col-4" v-else>
                                    <div class="btn btn-info btn-sm" type="button" @click="createNewBank">Submit</div>
                                </div>
                                <pulse-loader 
                                    :loading="isLoading" 
                                    :color="color"
                                    v-if="isLoading" 
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import 'vue-datepicker-next/index.css';
import Api from '../../services/Api';
import Swal from 'sweetalert2';

export default{
    components: {
        PulseLoader,
    },

    data(){
        return{
            data: {
              bank_name: '',
              bank_code: '',
              address: '',
              email: '',
            },

            isLoading: false,
            color: '#40aac7',
            errors: '',

            bank_branches: []
        }
    },

    methods: {
       async createNewBank(){
        this.isLoading = true
        await Api().post('banks',this.data)
        .then(res =>{
            if(res.status === 200){
                this.isLoading = false
                Swal.fire({
                    title: "Good job!",
                    text: `${res.data.message}`,
                    icon: "success"
                });
                this.data.bank_name = ''
                this.data.bank_code = ''
                this.data.email = ''
                this.$router.push('/bank-lists')
            }
        }).catch(error => {
            if(error.response.status === 422){
                this.isLoading = false
                this.errors = error.response.data.errors
            }
        })
       },
    }
}
</script>

<style>
.size{
    height: 34px;
    border-color: aliceblue;
}

.span-style{
    margin-left: 2px;
    font-weight: 500;
}
</style>