<template>
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-8" style="margin-bottom: 5%;">
                <div class="card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-10">
                                <span style="font-size: 18px;">New Expense</span>
                            </div>
                            <div class="col-2">
                                <RouterLink to="/request-expenses">
                                    <button class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                                </RouterLink>
                            </div>
                        </div>
                    </div>
    
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="middle_name">Expense Category:</label>
                                    <select class="form-control" v-model="data.category_id">
                                        <option v-for="(category, i) in categories" :key="i" :value="category.id">{{ category.category_name }}</option>
                                    </select>
                                    <div v-if="errors.category_id">
                                        <span class="text-danger">{{ errors.category_id[0] }}*</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="first_name">Expense Name:</label>
                                    <input type="text" class="form-control" v-model="data.expenses_name" />
                                    <div v-if="errors.expenses_name">
                                        <span class="text-danger">{{ errors.expenses_name[0] }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div class="row">
                            <div class="col-6" v-if="isUpdating">
                                <div class="btn btn-info btn-sm disabled" type="button">Submit</div>
                            </div>
                            <div class="col-6" v-else>
                                <div class="btn btn-info btn-sm" @click="createExpense" type="button">Submit</div>
                            </div>
                            <pulse-loader 
                                :loading="isUpdating" 
                                :color="color"
                                v-if="isUpdating" 
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import 'vue-datepicker-next/index.css';
import Api from '../../services/Api';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Swal from 'sweetalert2';

export default{
    components: {
        PulseLoader,
    },

    data(){
        return{
            
            data: {
                expenses_name: '',
                category_id: '',
            },
            isLoading: false,
            isUpdating: false,
            color: '#40aac7',
            categories: [],
            errors: ''
        }
    },

    mounted(){
        this.getExpenseCategories();
    },

    methods: {

        async getExpenseCategories(){
            this.isLoading = true
            await Api().get('expenses-categories')
            .then(res =>{
                try{
                    if(res.status === 200){
                        this.isLoading = false
                        this.categories = res.data
                    }
                }catch(error){
                    this.isLoading = false
                    console.log(error)
                }
            })
        },

        async createExpense(){
            this.isUpdating = true
            await Api().post('expenses',this.data)
            .then(res =>{
                if(res.status === 200){
                    this.isUpdating = false
                    Swal.fire({
                        title: "Good job!",
                        text: `${res.data.message}`,
                        icon: "success"
                    });
                    this.$router.push('/expenses-lis')
                }
                else{
                    this.isUpdating = false
                    Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: `${res.data.error}`,
                    });
                }
            }).catch(error =>{
                this.isUpdating = false
                if(error.response.status === 422){
                    this.errors = error.response.data.errors
                }
            })
        }
    }
}
</script>