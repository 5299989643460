import { createRouter,createWebHistory } from "vue-router";
import IndividualMemberList from "../pages/members/IndividualMemberList.vue";
import DashboardComponent from "../pages/dashboard/Dashboard.vue";
import CreateNewMember from "../pages/members/CreateNewMember.vue";
import EditIndividualMember from "../pages/members/EditIndividualMember.vue";
import ViewIndividualMember from "../pages/members/ViewIndividualMember.vue";
import UsersLists from "../pages/users/UsersLists.vue";
import CreateNewUser from "../pages/users/CreateNewUser.vue";
import BranchLists from "../pages/branches/BranchLists.vue";
import NewBranch from "../pages/branches/NewBranch.vue";
import BranchEdit from "../pages/branches/BranchEdit.vue";
import EditUser from "../pages/users/EditUser.vue";
import ViewUser from "../pages/users/ViewUser.vue";
import Login from "../pages/auth/Login.vue";
import LoanPlanLists from "../pages/loan management/LoanPlanLists.vue";
import NewPlan from "../pages/loan management/NewPlan.vue";
import EditPlan from "../pages/loan management/EditPlan.vue";
import ViewPlan from "../pages/loan management/ViewPlan.vue";
import CreateLoan from "../pages/loan management/CreateLoan.vue";
import LoanLists from "../pages/loan management/LoanLists.vue";
import PendingLoanLists from "../pages/disbursment/PendingLoanLists.vue";
import ApproveLoan from "../pages/disbursment/ApproveLoan.vue";
import ApprovedLists from "../pages/disbursment/ApprovedLists.vue";
import ViewApprovedLoan from "../pages/disbursment/ViewApprovedLoan.vue";
import BankLists from "../pages/banks/BankLists.vue";
import NewBank from "../pages/banks/NewBank.vue";
import BankEdit from "../pages/banks/BankEdit.vue";
import NewBankBranch from "../pages/banks/NewBankBranch.vue";
import BankBranchList from "../pages/banks/BankBranchList.vue";
import EditBankBranch from "../pages/banks/EditBankBranch.vue";
import BankAccountsList from "../pages/banks/BankAccountsList.vue";
import NewBankAccount from "../pages/banks/NewBankAccount.vue";
import EditBankAccount from "../pages/banks/EditBankAccount.vue";
import NewCapital from "../pages/banks/NewCapital.vue";
import TransferCapital from "../pages/banks/TransferCapital.vue";
import EmiCollection from "../pages/collection/EmiCollection.vue";
import PaymentCollection from "../pages/collection/PaymentCollection.vue";
import MemberLoansList from "../pages/members/MemberLoansList.vue";
import AccountStatement from "../pages/reports/AccountStatement.vue";
import NewExpensesCategory from "../pages/Expenses/NewExpensesCategory.vue";
import ExpensesCategoryList from "../pages/Expenses/ExpensesCategoryList.vue";
import EditExpensesCategory from "../pages/Expenses/EditExpensesCategory.vue";
import ExpensesList from "../pages/Expenses/ExpensesList.vue";
import NewExpenses from "../pages/Expenses/NewExpenses.vue";
import EditExpenses from "../pages/Expenses/EditExpenses.vue";
import RequestExpenses from "../pages/Expenses/RequestExpenses.vue";
import store from "../store/store";
import Settings from "../pages/settings/Settings.vue";

const router = createRouter({
    history: createWebHistory(),

    routes: [
        {
            path: '/login',
            name: 'login',
            component: Login,
        },
        {
            path: '/',
            name: 'dashboard',
            component: DashboardComponent,
            meta: {auth: true}
        },
        {
            path: '/create-member',
            name: 'create-member',
            component: CreateNewMember,
            meta: {auth: true}
        },
        {
            path: '/individual-member-list',
            name: 'individual-member-list',
            component: IndividualMemberList,
            meta: {auth: true}
        },
        {
            path: '/edit-individual-member/:id',
            name: 'edit-individual-member',
            component: EditIndividualMember,
            meta: {auth: true}
        },
        {
            path: '/individual-member/:id/view',
            name: 'individual-member',
            component: ViewIndividualMember,
            meta: {auth: true}
        },
        {
            path: '/create-branch',
            name: 'create-branch',
            component: NewBranch,
            meta: {auth: true}
        },
        {
            path: '/branches-list',
            name: 'branches-list',
            component: BranchLists,
            meta: {auth: true}
        },
        {
            path: '/branches/:id/edit',
            name: 'edit-branches',
            component: BranchEdit,
            meta: {auth: true}
        },
        {
            path: '/users-list',
            name: 'users-list',
            component: UsersLists,
            meta: {auth: true}
        },
        {
            path: '/create-user',
            name: 'create-user',
            component: CreateNewUser,
            meta: {auth: true}
        },
        {
            path: '/user/:id/edit',
            name: 'edit-user',
            component: EditUser,
            meta: {auth: true}
        },
        {
            path: '/user/:id/view',
            name: 'view-user',
            component: ViewUser,
            meta: {auth: true}
        },
        {
            path: '/loan-plan-lists',
            name: 'loan-plan-lists',
            component: LoanPlanLists,
            meta: {auth: true}
        },
        {
            path: '/new-plan',
            name: 'new-plan',
            component: NewPlan,
            meta: {auth: true}
        },
        {
            path: '/edit-plan/:id',
            name: 'edit-plan',
            component: EditPlan,
            meta: {auth: true}
        },
        {
            path: '/view-plan/:id',
            name: 'view-plan',
            component: ViewPlan,
            meta: {auth: true}
        },
        {
            path: '/loan-lists',
            name: 'loan-lists',
            component: LoanLists,
            meta: {auth: true}
        },
        {
            path: '/create-new-loan',
            name: 'create-new-loan',
            component: CreateLoan,
            meta: {auth: true}
        },
        {
            path: '/pending-loan-lists',
            name: 'pending-loan-lists',
            component: PendingLoanLists,
            meta: {auth: true}
        },
        {
            path: '/approve-loan/:id',
            name: 'approve-loan',
            component: ApproveLoan,
            meta: {auth: true}
        },
        {
            path: '/approved-loan',
            name: 'approved-loan',
            component: ApprovedLists,
            meta: {auth: true}
        },
        {
            path: '/view-approved-loan/:id',
            name: 'view-approved-loan',
            component: ViewApprovedLoan,
            meta: {auth: true}
        },
        {
            path: '/bank-lists',
            name: 'bank-lists',
            component: BankLists,
            meta: {auth: true}
        },
        {
            path: '/new-bank',
            name: 'new-bank',
            component: NewBank,
            meta: {auth: true}
        },
        {
            path: '/bank-edit/:id',
            name: 'bank-edit',
            component: BankEdit,
            meta: {auth: true}
        },
        {
            path: '/bank-branch-list',
            name: 'bank-branch-list',
            component: BankBranchList,
            meta: {auth: true}
        },
        {
            path: '/new-bank-branch',
            name: 'new-bank-branch',
            component: NewBankBranch,
            meta: {auth: true}
        },
        {
            path: '/edit-bank-branch/:id',
            name: 'edit-bank-branch',
            component: EditBankBranch,
            meta: {auth: true}
        },
        {
            path: '/bank-account-list',
            name: 'bank-account-list',
            component: BankAccountsList,
            meta: {auth: true}
        },
        {
            path: '/new-bank-account',
            name: 'new-bank-account',
            component: NewBankAccount,
            meta: {auth: true}
        },
        {
            path: '/edit-bank-account/:id',
            name: 'edit-bank-account',
            component: EditBankAccount,
            meta: {auth: true}
        },
        {
            path: '/new-capital/:id',
            name: 'new-capital',
            component: NewCapital,
            meta: {auth: true}
        },
        {
            path: '/transfer-capital/:id',
            name: 'transfer-capital',
            component: TransferCapital,
            meta: {auth: true}
        },
        {
            path: '/emi-collection',
            name: 'emi-collection',
            component: EmiCollection,
            meta: {auth: true}
        },
        {
            path: '/payment-collection/:id',
            name: 'payment-collection',
            component: PaymentCollection,
            meta: {auth: true}
        },
        {
            path: '/member-loans-list/:id',
            name: 'member-loans-list',
            component: MemberLoansList,
            meta: {auth: true}
        },
        {
            path: '/accounts-statement',
            name: 'accounts-statement',
            component: AccountStatement,
            meta: {auth: true}
        },
        {
            path: '/new-expenses-category',
            name: 'new-expenses-category',
            component: NewExpensesCategory,
            meta: {auth: true}
        },
        {
            path: '/expenses-category-list',
            name: 'expenses-category-list',
            component: ExpensesCategoryList,
            meta: {auth: true}
        },
        {
            path: '/edit-expenses-category/:id',
            name: 'edit-expenses-category',
            component: EditExpensesCategory,
            meta: {auth: true}
        },
        {
            path: '/expenses-lis',
            name: 'expenses-lis',
            component: ExpensesList,
            meta: {auth: true}
        },
        {
            path: '/new-expenses',
            name: 'new-expenses',
            component: NewExpenses,
            meta: {auth: true}
        },
        {
            path: '/edit-expenses/:id',
            name: 'edit-expenses',
            component: EditExpenses,
            meta: {auth: true}
        },
        {
            path: '/request-expenses',
            name: 'request-expenses',
            component: RequestExpenses,
            meta: {auth: true}
        },
        {
            path: '/settings',
            name: 'settings',
            component: Settings,
            meta: {auth: true}
        },
    ]
})

router.beforeEach((to, from, next) => {
    // const loggedIn = localStorage.getItem('token')
    const isAuthenticated = store.getters.isAuthenticated;
  
    if (to.matched.some(record => record.meta.auth) && !isAuthenticated) {
      next('/login')
      return
    }
    next()
})
  

// router.beforeEach((to, from, next) => {
//     const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
//     const token = localStorage.getItem('token');
  
//     if (requiresAuth && !token) {
//       next('/login');
//     } else {
//       next();
//     }
//   });

export default router