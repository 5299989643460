<template>
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-6" style="margin-bottom: 5%;">
                <div class="card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-md-10">
                                <span style="font-size: 18px;">New Branch Informations</span>
                            </div>
                            <div class="col-md-2">
                                <RouterLink to="/branches-list">
                                    <button class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                                </RouterLink>
                            </div>
                        </div>
                    </div>
    
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="branch_name">Branch Name:</label>
                                    <input type="text" class="form-control" v-model="data.branch_name" />
                                    <div v-if="errors.branch_name">
                                        <span class="text-danger">{{ errors.branch_name[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div class="row">
                            <div class="col-6" v-if="isLoading">
                                <div class="btn btn-info btn-sm disabled" type="button">Submit</div>
                            </div>
                            <div class="col-6" v-else>
                                <div class="btn btn-info btn-sm" type="button" @click="createNewBranch">Submit</div>
                            </div>
                            <pulse-loader 
                                :loading="isLoading" 
                                :color="color"
                                v-if="isLoading" 
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import 'vue-datepicker-next/index.css';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Swal from 'sweetalert2';
import BranchServices from '../../services/BranchServices';

export default{
    components: {
        PulseLoader,
    },

    data(){
        return{
            
            data: {
                branch_name: null,
                status: null,
            },
            isLoading: false,
            color: '#40aac7',
            errors: ''
        }
    },


    methods: {
        async createNewBranch(){
            this.isLoading = true
            try{
                const res = await BranchServices.regiserBranch(this.data.branch_name);
                if(res.status === 200){
                    this.isLoading = false
                    Swal.fire({
                        title: "Good job!",
                        text: "New Branch created!",
                        icon: "success"
                    });
                    this.data.branch_name = null
                }else{
                    this.isLoading = false
                    this.data.branch_name = null
                    Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: `${res.data.message}`,
                    });
                }
            }catch(error){
                this.isLoading = false
                if(error.response.status === 422){
                    this.errors = error.response.data.errors
                }
            }
        }
    }
}
</script>