<template>
    <div class="container-fluid">
        <div class="row justify-content-center" style="margin-bottom: 5%;">
            <div class="col-8">
                <div class="card" style="margin-bottom: 5%;">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-10">
                                <span style="font-size: 18px;">View User Informations</span>
                            </div>
                            <div class="col-2">
                                <RouterLink to="/users-list">
                                    <button class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                    <pulse-loader 
                        :loading="isLoading" 
                        :color="color"
                        v-if="isLoading" 
                        style="margin-top: 50px; margin-left: 40%;"
                    />
                    <div class="card-body" v-else>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="first_name">Full Name:</label>
                                    <input type="text" class="form-control" v-model="data.full_name" disabled/>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="middle_name">Phone:</label>
                                    <input type="text" class="form-control" v-model="data.phone" disabled/>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="last_name">Email:</label>
                                    <input type="text" class="form-control" v-model="data.email" disabled/>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Select Branch</label>
                                    <select class="form-control select2" v-model="data.branchId" disabled>
                                        <option >Select Branch</option>
                                        <option v-for="(barnch, i) in branches" :key="i" :value="barnch.id">{{ barnch.branch_name }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Select Status</label>
                                    <select class="form-control select2" v-model="data.status" disabled>
                                        <option >Select Status</option>
                                        <option >Active</option>
                                        <option >In Active</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import 'vue-datepicker-next/index.css';
import Api from '../../services/Api';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

export default{
    components: {
        PulseLoader,
    },

    data(){
        return{
            
            data: {
               full_name: null,
               email: null,
               branchId: null,
               phone: null,
               status: 'Active',
               id: this.$route.params.id,
            },
            isLoading: false,
            isUpdating: false,
            color: '#40aac7',
            users: [],
            branches: []
        }
    },

    mounted(){
        this.getBranches();
        this.getEditData();
    },

    methods: {

        async getBranches(){
            this.isLoading = true
            await Api().get(`branches`)
            .then(res =>{
                try{
                    if(res.status === 200){
                        this.isLoading = false
                        this.branches = res.data
                    }
                }catch(error){
                    this.isLoading = false
                    console.log(error)
                }
            })
        },

        async getEditData(){
            this.isLoading = true
            await Api().get(`users/${this.data.id}`)
            .then(res =>{
                try{
                    if(res.status === 200){
                       this.isLoading
                       this.data.full_name = res.data.full_name
                       this.data.email = res.data.email
                       this.data.phone = res.data.phone
                       this.data.status = res.data.status
                       this.data.branchId = res.data.branchId
                    }
                }catch(error){
                    this.isLoading
                    console.log(error)
                }
            })
        },
    }
}
</script>