<template>
  <body class="hold-transition sidebar-mini layout-fixed sidebar-collapse">
    <Login v-if="!isAuthenticated"/>
    <div class="wrapper" v-else>
      <nav-bar />
      <side-bar />
      <div class="content-wrapper">
        <content-header />
        <section class="content">
          <div class="container-fluid">
            <RouterView />
          </div>
        </section>
      </div>
      <Footer></Footer>
      <control-side-bar />
    </div>
  </body>
</template>

<script>
import $ from 'jquery'
import "../node_modules/admin-lte/plugins/select2-bootstrap4-theme/select2-bootstrap4.min.css"
import "../node_modules/admin-lte/plugins/select2/css/select2.min.css"
import NavBar from '../src/components/Navbar.vue'
import SideBar from '../src/components/Sidebar.vue'
import ContentHeader from '../src/components/ContentHeader.vue'
import Footer from '../src/components/Footer.vue'
import ControlSideBar from '../src/components/ControlSidebar.vue'
import Login from './pages/auth/Login.vue'
import { mapGetters } from 'vuex'

export default {
  name: "App",
  components: {
    NavBar,
    SideBar,
    ContentHeader,
    Footer,
    ControlSideBar,
    Login
  },

  data(){
    return {
      isLoggedIn: localStorage.getItem('token')
    }
  },

  computed: {
    ...mapGetters(['isAuthenticated'])
  },

  methods: {
    initializeSelect2() {
      //Initialize Select2 Elements
      $(".select2").select2();

      //Initialize Select2 Elements
      $(".select2bs4").select2({
        theme: "bootstrap4",
      });
    },
  },
  mounted() {
    this.initializeSelect2()
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700&display=fallback");
</style>
