<template>
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-md-6" style="margin-top: 5%;">
                <div class="card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-md-10">
                                <span style="font-size: 18px;">New Expenses Category</span>
                            </div>
                            <div class="col-md-2">
                                <RouterLink to="/request-expenses">
                                    <button class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                                </RouterLink>
                            </div>
                        </div>
                    </div>
    
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="branch_name">Category Name:</label>
                                    <input type="text" class="form-control" v-model="data.category_name" />
                                    <div v-if="errors.category_name">
                                        <span class="text-danger">{{ errors.category_name[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div class="row">
                            <div class="col-6" v-if="isLoading">
                                <div class="btn btn-info btn-sm disabled" type="button">Submit</div>
                            </div>
                            <div class="col-6" v-else>
                                <div class="btn btn-info btn-sm" type="button" @click="createNewCategory">Submit</div>
                            </div>
                            <pulse-loader 
                                :loading="isLoading" 
                                :color="color"
                                v-if="isLoading" 
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import 'vue-datepicker-next/index.css';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Swal from 'sweetalert2';
import Api from '../../services/Api';

export default{
    components: {
        PulseLoader,
    },

    data(){
        return{
            
            data: {
                category_name: null,
                status: null,
            },
            isLoading: false,
            color: '#40aac7',
            errors: ''
        }
    },


    methods: {
        async createNewCategory(){
            this.isLoading = true
            await Api().post('expenses-categories',this.data)
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.data.category_name = ''
                    Swal.fire({
                        title: "Good job!",
                        text: `${res.data.message}`,
                        icon: "success"
                    });
                    this.$router.push('/expenses-category-list')
                }else{
                    this.isLoading = false
                    this.data.category_name = ''
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: `${res.data.error}`,
                    });
                }
            }).catch(error =>{
                if(error.response.status === 422){
                    this.isLoading = false
                    this.errors = error.response.data.errors
                }
            })
        }
    }
}
</script>