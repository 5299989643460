<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card" style="margin-bottom: 5%;">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-10">
                               <h5>Create New Plan</h5>
                            </div>
                            <div class="col-2">
                                <RouterLink to="/loan-lists">
                                    <button class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row justify-content-center">
                            <div class="col-4">
                                <div class="form-group">
                                    <span class="span-style">Select Member</span>
                                    <select class="form-control size mt-2" v-model="data.memberId">
                                        <option>Select Member</option>
                                        <option v-for="(member, i) in members" :key="i" :value="member.id">{{ member.first_name }} {{ member.middle_name }} {{ member.last_name }}</option>
                                    </select>
                                    <div v-if="errors.memberId">
                                        <span class="text-danger">{{ errors.memberId[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <span class="span-style">Select Plan</span>
                                    <select class="form-control size mt-2" v-model="data.loan_planId">
                                        <option>Select Loan Plan</option>
                                        <option v-for="(loan, i) in loansPlan" :key="i" :value="loan.id">{{ loan.plan_name }}</option>
                                    </select>
                                    <div v-if="errors.loan_planId">
                                        <span class="text-danger">{{ errors.loan_planId[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row justify-content-center">
                            <div class="col-4">
                                <div class="form-group">
                                    <span class="span-style">Loan Amount Type</span>
                                    <select class="form-control select2 size mt-2">
                                        <option>Static</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-4">
                                
                            </div>
                        </div>
                        
                        <div class="row justify-content-center">
                            <div class="col-4" v-if="isLoading">
                                <div class="btn btn-info btn-sm disabled" type="button">Creating...</div>
                            </div>
                            <div class="col-4" v-else>
                                <div class="btn btn-info btn-sm" @click="createLoan" type="button">Create</div>
                            </div>
                            <pulse-loader 
                                :loading="isLoading" 
                                :color="color"
                                v-if="isLoading" 
                            />
                            <div class="col-4"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Api from '../../services/Api';
import Swal from 'sweetalert2';

import $ from 'jquery'
import "../../../node_modules/admin-lte/plugins/select2/css/select2.min.css"
import "../../../node_modules/admin-lte/plugins/select2/css/select2.min.css"

export default{
    components: {
        PulseLoader,
    },

    data(){
        return{
            data: {
                memberId: '',
                loan_planId: '',
                // status: 'Active',
            },
            isLoading: false,
            color: '#40aac7',
            errors: '',
            members: [],
            loansPlan: [],
        }
    },

    mounted(){
        this.fetchData();
        this.fetchLoanPlan();
        this.initializeSelect2();
    },

    methods: {
        initializeSelect2() {
        //Initialize Select2 Elements
        $(".select2").select2();

        //Initialize Select2 Elements
        $(".select2bs4").select2({
            theme: "bootstrap4",
        });
        
        },
        async fetchData(){
            this.isLoading = true
          await Api().get('members')
          .then(res =>{
            if(res.status === 200){
                this.isLoading = false
                this.members = res.data
            }else{
                console.log('error')
            }
          })
        },

        async fetchLoanPlan(){
            this.isLoading = true
            await Api().get('loan-plans')
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.loansPlan = res.data
                }
            }).catch(error =>{
                this.isLoading = false
                console.error('Failed to get loan plan information',error)
            })
        },

        async createLoan(){
            this.isLoading = true
            await Api().post('loans', this.data)
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    Swal.fire({
                        title: "Good job!",
                        text: "New loan plan created!",
                        icon: "success"
                    });
                    this.$router.push('/loan-lists')
                }
            }).catch(error =>{
                if(error.response.status === 422){
                    this.isLoading = false
                    this.errors = error.response.data.errors
                }
            })
        }
    }
}
</script>

<style>
.size{
    height: 34px;
    border-color: aliceblue;
}

.span-style{
    margin-left: 2px;
    font-weight: 500;
}

.span-style{
    margin-left: 2px;
    font-weight: 500;
}
</style>