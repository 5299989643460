import axios from "axios";
import store from "../store/store";

export default () =>{
    return axios.create({
        baseURL: `https://microfinance-api.depima.co.tz/api/V1/`,
        headers: {
            Authorization: `Bearer ${store.getters.token}`
        }
    })
}