import Api from "./Api";

export default{
    async registerUser(full_name,email,phone,status,designation,comfirm_password,password,branch_name){
        const response = await Api().post('users',{
            full_name: full_name,
            email: email,
            phone: phone,
            status: status,
            designation: designation,
            comfirm_password: comfirm_password,
            password: password,
            branch_name: branch_name
        });
        return response;
    },

    async getData(){ 
        try{
            const response = await Api().get('users');
            if(response.status === 200){
                return response.data;
            }
        }catch(error){
            return console.error('Failed to get user',error)
        }
    }
}