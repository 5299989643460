<template>
    <!-- <ContentLoader v-if="isLoading"/> -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card" style="margin-bottom: 5%;">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-md-6">
                               <span style="font-weight: 500;">Request Expenses</span>
                            </div>
                            <div class="col-md-6">
                                <RouterLink to="/new-expenses">
                                    <button class="btn btn-info btn-sm mr-2"><i class="fas fa-satellite"></i> New Expense</button>
                                </RouterLink>
                                <RouterLink to="/expenses-lis">
                                    <button class="btn btn-info btn-sm mr-2"><i class="fas fa-wallet"></i> Expenses List</button>
                                </RouterLink>
                                <RouterLink to="/new-expenses-category">
                                    <button class="btn btn-info btn-sm mr-2"><i class="fas fa-snowflake"></i> New Category</button>
                                </RouterLink>
                                <RouterLink to="/expenses-category-list">
                                    <button class="btn btn-info btn-sm"><i class="fa fa-record-vinyl"></i> Category Lists</button>
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                    <pulse-loader 
                        :loading="isLoading" 
                        :color="color"
                        v-if="isLoading" 
                        style="margin-top: 50px; margin-left: 40%;"
                    />
                    <div class="card-body" v-else>
                        <div class="container-fluid " style="border-style: solid; border-width: 1px; border-color: aliceblue;">
                            <div class="row justify-content-center">
                                <div class="col-4">
                                   <div class="form-group">
                                        <span class="span-style">Select Category</span>
                                        <select class="form-control size" v-model="data.categoryId" @change="fetchExpenses">
                                            <option v-for="(category, i) in categories" :key="i" :value="category.id">{{ category.category_name }}</option>
                                        </select>
                                        <div v-if="errors.categoryId">
                                            <span class="text-danger">{{ errors.categoryId[0] }}*</span>
                                        </div>
                                   </div>
                                </div>
                                <div class="col-4 justify-content-center">
                                   <div class="form-group">
                                        <span class="span-style">Select Expenses</span>
                                        <select class="form-control size" v-model="data.expensesId">
                                            <option v-for="(expense, i) in expenses" :key="i" :value="expense.id">{{ expense.expenses_name }}</option>
                                        </select>
                                        <div v-if="errors.expensesId">
                                            <span class="text-danger">{{ errors.expensesId[0] }}*</span>
                                        </div>
                                   </div>
                                </div>
                            </div>

                            <div class="row justify-content-center">
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Payment Account</span>
                                        <select class="form-control size" v-model="data.accountId">
                                            <option v-for="(account, i) in accounts" :key="i" :value="account.id">{{ account.account_name }} - {{ account.account_no }} - {{ account.banks.bank_name }}</option>
                                        </select>
                                        <div v-if="errors.accountId">
                                            <span class="text-danger">{{ errors.accountId[0] }}*</span>
                                        </div>
                                   </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Amount</span>
                                        <input type="text" class="form-control size" v-model="data.amount"/>
                                        <div v-if="errors.amount">
                                            <span class="text-danger">{{ errors.amount[0] }}*</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row justify-content-center">
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Reference No</span>
                                        <input type="text" class="form-control size" v-model="data.ref_no"/>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Description</span>
                                        <input type="text" class="form-control size" v-model="data.description"/>
                                    </div>
                                </div>
                            </div>

                            <div class="row justify-content-center">
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Select Date</span><br>
                                        <date-picker v-model:value="data.date" value-type="YYYY-MM-DD" />
                                        <div v-if="errors.date">
                                            <span class="text-danger">{{ errors.date[0] }}*</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4"></div>
                            </div>

                            <div class="row mb-2">
                                <div class="col-2"></div>
                                <div class="col-4" v-if="isCreating">
                                    <button type="button" class="btn btn-info btn-sm" disabled>Submiting...</button>
                                </div>
                                <div class="col-4" v-else>
                                    <button type="button" class="btn btn-info btn-sm" @click="createExpenseRequest">Submit</button>
                                </div>
                                <pulse-loader 
                                :loading="isCreating" 
                                :color="color"
                                v-if="isCreating" 
                            />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import 'vue-datepicker-next/index.css';
import Api from '../../services/Api';
import DatePicker from 'vue-datepicker-next';
// import ContentLoader from '../../components/ContentLoader.vue';
import Swal from 'sweetalert2';

export default{
    components: {
        PulseLoader,
        // ContentLoader
        DatePicker
    },

    data(){
        return{
            data: {
                categoryId: '',
                expensesId: '',
                accountId: '',
                amount: '',
                date: '',
                description: '',
                ref_no: '',
            },
            categories: [],
            expenses: [],
            accounts: [],
            isLoading: false,
            isCreating: false,
            color: '#40aac7',
            errors: '',
        }
    },

    mounted(){
       this.fecthCategories();
       this.getBankAccounts();
    },

    methods: {
        async fecthCategories(){
            this.isLoading = true
            await Api().get('expenses-categories')
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.categories = res.data
                }
            }).catch(error =>{
                if(error){
                    console.log('Failed to get expenses categories')
                }
            })
        },

        async fetchExpenses(){
            await Api().get(`expensesby-categoryId/${this.data.categoryId}`)
            .then(res =>{
                if(res.status === 200){
                    this.expenses = res.data
                    this.data.expensesId = res.data[0].id
                }
            }).catch(error =>{
                if(error){
                    console.log('Failed to get expenses')
                }
            })
        },

        async createExpenseRequest(){
            this.isCreating = true
            await Api().post('save-expenses-request',this.data)
            .then(res =>{
                if(res.status === 200){
                    this.isCreating = false
                    Swal.fire({
                        title: "Good job!",
                        text: `${res.data.message}`,
                        icon: "success"
                    });
                    this.data.categoryId = ''
                    this.data.expensesId = ''
                    this.data.amount = ''
                    this.data.description = ''
                    this.data.date = ''
                    this.data.ref_no = ''
                    this.data.accountId =  ''
                }
            }).catch(error =>{
                if(error.response.status === 422){
                    this.isCreating = false
                    this.errors = error.response.data.errors
                }
            })
        },

        async getBankAccounts(){
            this.isLoading = true
            await Api().get('bank-accounts')
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.accounts = res.data
                }
            }).catch(error =>{
                if(error){
                    this.isLoading = false
                    console.log('Failed to get bank accounts')
                }
            })
        }
    }
}
</script>

<style>
.size{
    height: 34px;
    border-color: rgb(230, 233, 234);
}

.span-style{
    margin-left: 2px;
    font-weight: 500;
}
</style>