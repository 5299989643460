<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card" style="margin-bottom: 5%;">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-10">
                                <span v-if="!calculateEMI" style="font-size: 18px;">New Loan Plan</span>
                                <span v-else style="font-size: 18px;">EMI Calculations</span>
                            </div>
                            <div class="col-2" v-if="!calculateEMI">
                                <RouterLink to="/loan-lists">
                                    <button class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                    <div class="card-body" v-if="!calculateEMI">
                        <div class="row">
                            <div class="col-4">
                                <div class="form-group">
                                    <label>Plan Name</label>
                                    <input type="text" class="form-control" v-model="data.plan_name"/>
                                    <div v-if="errors.plan_name">
                                        <span class="text-danger">{{ errors.plan_name[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <label>Plan Type</label>
                                    <select class="form-control" v-model="data.plan_type">
                                        <option >Normal Plan</option>
                                        <option >Special Plan</option>
                                    </select>
                                    <div v-if="errors.plan_type">
                                        <span class="text-danger">{{ errors.plan_type[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <label>Plan Amount</label>
                                    <input type="text" class="form-control" v-model="data.plan_amount"/>
                                    <div v-if="errors.plan_amount">
                                        <span class="text-danger">{{ errors.plan_amount[0] }}*</span>
                                    </div>
                                    <div v-if="errors.principal">
                                        <span class="text-danger">{{ errors.principal[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-4">
                                <div class="form-group">
                                    <label>Interest Rate</label>
                                    <input type="text" class="form-control" v-model="data.interest_rate"/>
                                    <div v-if="errors.interest_rate">
                                        <span class="text-danger">{{ errors.interest_rate[0] }}*</span>
                                    </div>
                                    <div v-if="errors.annual_interest_rate">
                                        <span class="text-danger">{{ errors.annual_interest_rate[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <label>File Charge Include</label>
                                    <select class="form-control" v-model="data.file_charge_include">
                                        <option>Yes</option>
                                        <option>No</option>
                                    </select>
                                    <div v-if="errors.file_charge_include">
                                        <span class="text-danger">{{ errors.file_charge_include[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <label>File Charge</label>
                                    <input type="text" class="form-control" v-model="data.file_charge_amount"/>
                                    <div v-if="errors.file_charge_amount">
                                        <span class="text-danger">{{ errors.file_charge_amount[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-4">
                                <div class="form-group">
                                    <label>Recovery Type</label>
                                    <select class="form-control" v-model="data.recovery_type">
                                        <option :value="52">Weeky</option>
                                        <option :value="12">Monthly</option>
                                        <option :value="3">Quarterly</option>
                                        <option :value="6">Half Year</option>
                                    </select>
                                    <div v-if="errors.recovery_type">
                                        <span class="text-danger">{{ errors.recovery_type[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <label>Number of Payments</label>
                                    <input type="text" class="form-control" v-model="data.number_of_payment"/>
                                    <div v-if="errors.number_of_payment">
                                        <span class="text-danger">{{ errors.number_of_payment[0] }}*</span>
                                    </div>
                                    <div v-if="errors.tenure_in_weeks">
                                        <span class="text-danger">{{ errors.tenure_in_weeks[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <label>Type of Interest</label>
                                    <select class="form-control" v-model="data.type_of_interest">
                                        <option>Fixed</option>
                                        <option>Reduced</option>
                                    </select>
                                    <div v-if="errors.type_of_interest">
                                        <span class="text-danger">{{ errors.type_of_interest[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-4">
                                <div class="form-group">
                                    <label>Status</label>
                                    <select class="form-control" v-model="data.status">
                                        <option>Active</option>
                                        <option>InActive</option>
                                    </select>
                                    <div v-if="errors.status">
                                        <span class="text-danger">{{ errors.status[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <label>Penalty Rate</label>
                                    <input type="text" class="form-control" v-model="data.penalty_rate"/>
                                    <div v-if="errors.penalty_rate">
                                        <span class="text-danger">{{ errors.penalty_rate[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-1" v-if="isLoading">
                                <div class="btn btn-success btn-sm disabled" type="button">Save</div>
                            </div>
                            <div class="col-1" v-else>
                                <div class="btn btn-success btn-sm" type="button" @click="registerLoanPlan">Save</div>
                            </div>
                            <div class="col-2">
                                <div class="btn btn-info btn-sm" @click="calculateWeekyEMI" type="button">Calculate EMI</div>
                            </div>
                            <pulse-loader 
                                :loading="isLoading" 
                                :color="color"
                                v-if="isLoading" 
                            />
                        </div>
                    </div>

                    <pulse-loader 
                        :loading="emiLoading" 
                        :color="color"
                        v-if="emiLoading" 
                        style="margin-top: 50px; margin-left: 40%;"
                    />

                    <div class="card-body" v-if="calculateEMI">
                        <div class="row" v-if="!emiLoading">
                            <div class="col-12">
                                <div class="row mb-2">
                                    <div class="col-md-3">
                                        <span>Number of Payment: <b>{{ weeklyEmiSingle.tenure }}</b></span>
                                    </div>
                                    <div class="col-md-3">
                                        <span>Total Interest: <b>{{ formatNumber(weeklyEmiSingle.total_interest) }}</b></span>
                                    </div>
                                    <div class="col-md-3">
                                        <span>Total Amount To Pay: <b>{{ formatNumber(weeklyEmiSingle.total_payment) }}</b></span>
                                    </div>
                                    <div class="col-md-3">
                                        <span>Principal Amount: <b>{{ formatNumber(weeklyEmiSingle.principal) }}</b></span>
                                    </div>
                                </div>
                                <table id="example1" class="table table-hover table-bordered table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <td>Date</td>
                                            <td>Interest</td>
                                            <td>EMI</td>
                                            <td>Balance</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(emi, i) in weeklyEmi" :key="i">
                                            <td>{{ emi.date }}</td>
                                            <td>{{ formatNumber(emi.interest_payment) }}</td>
                                            <td>{{ formatNumber(emi.emi) }}</td>
                                            <td>{{ formatNumber(emi.remaining_balance) }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="row" v-if="!emiLoading">
                            <div class="col-4">
                                <button type="button" @click="backButton" class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from '../../services/Api';
import Swal from 'sweetalert2';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

export default{
    components: {
        PulseLoader,
    },

    data(){
        return{
            data: {
                plan_name: '',
                plan_type: 'Normal Plan',
                plan_amount: '',
                interest_rate: '',
                file_charge_include: 'No',
                file_charge_amount: 0,
                number_of_payment: '',
                recovery_type: '',
                type_of_interest: 'Fixed',
                status: 'Active',
                penalty_rate: 0,
            },
            isLoading: false,
            color: '#40aac7',
            errors: '',
            calculateEMI: false,
            weeklyEmi: [],
            weeklyEmiSingle: [],
            emiLoading: false
        }
    },

    methods: {
        async registerLoanPlan(){
            this.isLoading = true
            await Api().post('loan-plans',this.data)
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    Swal.fire({
                        title: "Good job!",
                        text: "New loan plan created!",
                        icon: "success"
                    });
                    this.$router.push('/loan-plan-lists')
                    this.data.plan_name = '',
                    this.data.plan_amount = '',
                    this.data.interest_rate = '',
                    this.data.file_charge_amount = '',
                    this.data.number_of_payment = ''
                }else{
                    this.isLoading = false
                    this.data.plan_name = '',
                    this.data.plan_amount = '',
                    this.data.interest_rate = '',
                    this.file_charge_amount = '',
                    this.data.number_of_payment = ''

                    Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: `${res.data.message}`,
                    });
                }
            }).catch(error =>{
                if(error.response.status === 422){
                    this.isLoading = false
                    this.errors = error.response.data.errors
                }
            })
        },

        async calculateWeekyEMI(){
            this.calculateEMI = true
            this.emiLoading = true
            await Api().post('weekly-emi',{
                principal: this.data.plan_amount,
                annual_interest_rate:this.data.interest_rate,
                tenure: this.data.number_of_payment,
                recovery_type: this.data.recovery_type,
                type_of_interest: this.data.type_of_interest
            })
            .then(res =>{
                this.emiLoading = false
                this.weeklyEmi = res.data.schedule,
                this.weeklyEmiSingle = res.data
            }).catch(error =>{
                if(error.response.status === 422){
                    this.calculateEMI = false
                    this.emiLoading = false
                    this.errors = error.response.data.errors
                }
            })
        },

        backButton(){
            this.calculateEMI = false
        },

        formatNumber(value){
            return new Intl.NumberFormat().format(value);
        }
    }
}
</script>

<style scoped>
    #example1{
        border-collapse: collapse; 
        border: 1px solid #839E99;
        background: #f1f8ee;  
        color: #033;
    }
</style>