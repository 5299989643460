<template>
    <div class="container-fluid">
      <ContentLoader v-if="isLoading"/> 
      <div class="row" v-else>
        <div class="col-12">
            <div class="card" style="margin-bottom: 5%;">
                <div class="card-header">
                    <div class="row">
                        <div class="col-md-10">
                            <h3 class="card-title">Members List</h3>
                        </div>
                        <div class="col-md-2">
                            <RouterLink to="/create-member">
                                <button type="button" class="btn btn-sm btn-info">New Member</button>
                            </RouterLink>
                        </div>
                    </div>
                </div>

                <div class="card-body">
                    <!-- <pulse-loader 
                    :loading="isLoading" 
                    :color="color"
                    v-if="isLoading" 
                    style="margin-top: 50px; margin-left: 40%;"
                    /> -->
                    <div class="row">
                        <div class="col-12">
                            <table id="example1" class="table table-hover table-bordered table-striped table-sm table-responsive-sm">
                                <thead>
                                    <tr>
                                    <th>Full Name</th>
                                    <th>Phone No</th>
                                    <th>Nida No</th>
                                    <th>Email</th>
                                    <th>Address</th>
                                    <th>View</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(member, i) in members" :key="i">
                                    <td>{{ member.first_name }} {{ member.midle_name }} {{ member.last_name }}</td>
                                    <td>{{ member.phone }}</td>
                                    <td>{{ member.nida_no }}</td>
                                    <td>{{ member.email }}</td>
                                    <td>{{ member.address }}</td>
                                    <td>
                                        <RouterLink :to="{name: 'individual-member',params: {id: member.id}}">
                                            <button class="btn btn-info btn-sm" style="background-color: #1d2363"><i class="fa fa-eye"></i></button>
                                        </RouterLink>
                                    </td>
                                    <td>
                                        <RouterLink :to="{name: 'edit-individual-member',params: {id: member.id}}">
                                            <button class="btn btn-info btn-sm"><i class="fa fa-edit"></i></button>
                                        </RouterLink>
                                    </td>
                                    <td>
                                        <button class="btn btn-danger btn-sm" @click="deleteConfirm(member.id)"><i class="fa fa-trash"></i></button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
</template>


<script>
import Api from '../../services/Api';
// import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Swal from 'sweetalert2';
import ContentLoader from '../../components/ContentLoader.vue';


export default {
    components: {
        // PulseLoader,
        ContentLoader
    },
    data() {
        return {
            members: [],
            deleteIdex: -1,
            isLoading: false,
            color: '#40aac7'
        }
    },

    mounted(){
        this.fetchData()
    },

    methods: {
        async fetchData(){
            this.isLoading = true
          await Api().get('members')
          .then(res =>{
            if(res.status === 200){
                this.isLoading = false
                this.members = res.data
            }else{
                console.log('error')
            }
          })
        },


        deleteConfirm(id){
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: "btn btn-success",
                    cancelButton: "btn btn-danger"
                },
                buttonsStyling: false
            });

            swalWithBootstrapButtons.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, cancel!",
            reverseButtons: true
            }).then((result) => {
            if (result.isConfirmed) {
                this.deleteMember(id);
                this.members.splice(this.deleteIdex, 1)
                swalWithBootstrapButtons.fire({
                title: "Deleted!",
                text: "Member has been deleted.",
                icon: "success"
                });
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire({
                title: "Cancelled",
                text: "Your member is safe",
                icon: "error"
                });
            }
            });
        },

        async deleteMember(id){
            this.isLoading = true
            await Api().delete(`members/${id}`)
            .then(res =>{
                try{
                    if(res.status === 200){
                        this.isLoading = false
                    }
                }catch(error){
                    console.log(error)
                }
            })
        },
    }
}
</script>

<style scoped>
    #example1{
        border-collapse: collapse; 
        border: 1px solid #839E99;
        background: #f1f8ee;  
        color: #033;
    }
</style>