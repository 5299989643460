<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card" style="margin-bottom: 5%;">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-10">
                               <span style="font-weight: 500;">New Bank Information</span>
                            </div>
                            <div class="col-2">
                                <RouterLink to="/bank-lists">
                                    <button class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                    <pulse-loader 
                        :loading="isLoading" 
                        :color="color"
                        v-if="isLoading" 
                        style="margin-top: 50px; margin-left: 40%;"
                    />
                    <div class="card-body" v-else>
                        <div class="container-fluid " style="border-style: solid; border-width: 1px; border-color: aliceblue;">
                            <div class="row mt-2 justify-content-center">
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Bank Name*</span>
                                        <input type="text" class="form-control size mt-2" v-model="data.bank_name"/>
                                        <div v-if="errors.bank_name">
                                        <span class="text-danger">{{ errors.bank_name[0] }}*</span>
                                    </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Bank Code*</span>
                                        <input type="text" class="form-control size mt-2" v-model="data.bank_code" placeholder="Optional"/>
                                    </div>
                                </div>
                            </div>

                            <div class="row justify-content-center">
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Address*</span>
                                        <input type="text" class="form-control size mt-2" v-model="data.address" placeholder="Optional"/>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Email*</span>
                                        <input type="text" class="form-control size mt-2" v-model="data.email" placeholder="Optional"/>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div class="col-2"></div>
                                <div class="col-4" v-if="updateLoading">
                                    <div class="btn btn-info btn-sm disabled" type="button">Updating...</div>
                                </div>
                                <div class="col-4" v-else>
                                    <div class="btn btn-info btn-sm" type="button" @click="updateBankInfo">Update</div>
                                </div>
                                <pulse-loader 
                                    :loading="updateLoading" 
                                    :color="color"
                                    v-if="updateLoading" 
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import 'vue-datepicker-next/index.css';
import Api from '../../services/Api';
import Swal from 'sweetalert2';

export default{
    components: {
        PulseLoader,
    },

    data(){
        return{
            data: {
              bank_name: '',
              bank_code: '',
              address: '',
              email: '',
              id: this.$route.params.id
            },

            isLoading: false,
            updateLoading: false,
            color: '#40aac7',
            errors: '',
        }
    },

    mounted(){
        this.fetchData();
    },

    methods: {
      async fetchData(){
        this.isLoading = true
        await Api().get(`banks/${this.data.id}`)
        .then(res =>{
            if(res.status === 200){
                this.isLoading = false
                this.data.bank_name = res.data[0].bank_name
                this.data.bank_code = res.data[0].bank_code
                this.data.address = res.data[0].address
                this.data.email = res.data[0].email
            }else{
                this.isUpdateLoading = false
                Swal.fire({
                icon: "error",
                title: "Error",
                text: `${res.data.message}`,
                });
            }
        }).catch(error =>{
            if(error){
                this.isLoading = false
                console.log('Failed to get bank information.')
            }
        })
      },

      async updateBankInfo(){
        this.updateLoading = true
        await Api().put(`banks/${this.data.id}`,this.data)
        .then(res =>{
            if(res.status === 200){
                this.updateLoading = false
                Swal.fire({
                    title: "Successfully!",
                    text: `${res.data.message}`,
                    icon: "success"
                });
                this.$router.push('/bank-lists')
            }
        }).catch(error =>{
            if(error){
                console.log('Failed to update bank information')
            }
        })
      }
    }
}
</script>

<style>
.size{
    height: 34px;
    border-color: aliceblue;
}

.span-style{
    margin-left: 2px;
    font-weight: 500;
}
</style>