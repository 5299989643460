<template>
  <!-- Main Sidebar Container -->
  <aside class="main-sidebar sidebar-light-primary elevation-1" style="position: fixed;">
    <!-- Brand Logo -->
    <RouterLink class="brand-link" to="/">
      <img src="../assets/img/depima_logo.png" alt="Depima Logo" class="brand-image img-circle elevation-1" style="opacity: .8">
      <span class="brand-text font-weight-bold">Elly Microfinance</span>
    </RouterLink>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <li class="nav-item">
            <RouterLink class="nav-link" to="/">
              <i class="nav-icon fas fa-th" style="color: #1d2363 !important;"></i>
              <p style="color: #1d2363 !important;">
                Dashboard
              </p>
            </RouterLink>
          </li>
        
          <li class="nav-item" v-if="hasPermission('members')">
            <!-- <RouterLink class="nav-link" to="/individual-member-list" :class="{active: isActive('/individual-member-list')}" >
              <i class="nav-icon fas fa-user-plus" style="color: #1d2363 !important;"></i>
              <p style="color: #1d2363 !important;">
                Members
              </p>
            </RouterLink> -->
            <RouterLink class="nav-link" to="/individual-member-list">
              <i class="nav-icon fas fa-user-plus" style="color: #1d2363 !important;"></i>
              <p style="color: #1d2363 !important;">
                Members
              </p>
            </RouterLink>
          </li>

          <li class="nav-item" v-if="hasPermission('loan_management')">
            <RouterLink to="/loan-lists" class="nav-link" style="color: #1d2363">
              <i class="nav-icon fas fa-wallet" style="color: #1d2363 !important;"></i>
              <p style="color: #1d2363 !important;">
                Loan Management
              </p>
            </RouterLink>
          </li>

          <li class="nav-item" v-if="hasPermission('disbursment')">
            <RouterLink to="/pending-loan-lists" class="nav-link" style="color: #1d2363">
              <i class="nav-icon fas fa-tachometer-alt" style="color: #1d2363 !important;"></i>
              <p style="color: #1d2363 !important;">
                Disbursment
              </p>
            </RouterLink>
          </li>

          <li class="nav-item" v-if="hasPermission('collection')">
            <RouterLink to="/emi-collection" class="nav-link" style="color: #1d2363">
              <i class="nav-icon fas fa-tag" style="color: #1d2363"></i>
              <p style="color: #1d2363">
                Collection
              </p>
            </RouterLink>
          </li>

          <li class="nav-item" v-if="hasPermission('accounting')">
            <RouterLink to="/bank-account-list" class="nav-link" style="color: #1d2363">
              <i class="nav-icon fas fa-pen-nib" style="color: #1d2363"></i>
              <p style="color: #1d2363">
                Accounting
              </p>
            </RouterLink>
          </li>

          <li class="nav-item" v-if="hasPermission('expenses')">
            <RouterLink to="/request-expenses" class="nav-link" style="color: #1d2363;">
              <i class="nav-icon fas fa-scroll" style="color: #1d2363"></i>
              <p style="color: #1d2363">
                Expenses
              </p>
            </RouterLink>
          </li>

          <li class="nav-item" v-if="hasPermission('user_access')">
            <RouterLink to="/users-list" class="nav-link" style="color: #1d2363;">
              <i class="nav-icon fas fa-user-alt" style="color: #1d2363"></i>
              <p style="color: #1d2363">
                User Access
              </p>
            </RouterLink>
          </li>

          <li class="nav-item" v-if="hasPermission('branches')">
            <RouterLink to="/branches-list" class="nav-link" style="color: #1d2363;">
              <i class="nav-icon fas fa-code-branch" style="color: #1d2363"></i>
              <p style="color: #1d2363">
                Branch
              </p>
            </RouterLink>
          </li>

          <li class="nav-item" v-if="hasPermission('settings')">
            <RouterLink to="/settings" class="nav-link" style="color: #1d2363;">
              <i class="nav-icon fas fa-cog" style="color: #1d2363"></i>
              <p style="color: #1d2363">
                Settings
              </p>
            </RouterLink>
          </li>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
import Api from '../services/Api';
import store from '../store/store';

export default {
  data(){
    return{
      data: {
        userId: store.state.user.id,
      },
      permissions: [],
    }
  },

  mounted(){
    this.fetchPermissions();
  },

  methods: {
    isActive(path){
      return this.$route.path === path;
    },

    async fetchPermissions(){
      this.isLoading = true
      await Api().get(`permissions/${this.data.userId}`)
      .then(res =>{
        if(res.status === 200){
          this.isLoading = false
          this.permissions = res.data
        }
      }).catch(error =>{
        if(error){
          this.isLoading = false
          console.error('Failed to fetch permissions.',error)
        }
      });
    },

    hasPermission(permissionKey) {
      return this.permissions.some(permission => permission.key === permissionKey && permission.value);
    }
  }
}
</script>

<style>

</style>