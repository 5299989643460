<template>
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-6" style="margin-bottom: 5%;">
                <div class="card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-10">
                                <span style="font-size: 18px;">Edit Branch Informations</span>
                            </div>
                            <div class="col-2">
                                <RouterLink to="/branches-list">
                                    <button class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                                </RouterLink>
                            </div>
                        </div>
                    </div>

                    <pulse-loader 
                        :loading="isLoading" 
                        :color="color"
                        v-if="isLoading" 
                        style="margin-top: 50px; margin-left: 40%;"
                    />
    
                    <div class="card-body" v-else>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="branch_name">Branch Name:</label>
                                    <input type="text" class="form-control" v-model="data.branch_name" />
                                    <div v-if="errors.branch_name">
                                        <span class="text-danger">{{ errors.branch_name[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Status</label>
                                    <select class="form-control select2" v-model="data.status">
                                        <option selected="selected">Active</option>
                                        <option>In Active</option>
                                    </select>
                                    <div v-if="errors.status">
                                        <span class="text-danger">{{ errors.status[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div class="row">
                            <div class="col-6" v-if="isUpdateLoading">
                                <div class="btn btn-info btn-sm disabled" type="button">Submit</div>
                            </div>
                            <div class="col-6" v-else>
                                <div class="btn btn-info btn-sm" type="button" @click="updateBranch">Submit</div>
                            </div>
                            <pulse-loader 
                                :loading="isUpdateLoading" 
                                :color="color"
                                v-if="isUpdateLoading" 
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import 'vue-datepicker-next/index.css';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Swal from 'sweetalert2';
import BranchServices from '../../services/BranchServices';

export default{
    components: {
        PulseLoader,
    },

    data(){
        return{
            data: {
                branch_name: null,
                status: null,
                id: this.$route.params.id,
            },
            isLoading: false,
            isUpdateLoading: false,
            color: '#40aac7',
            errors: ''
        }
    },

    mounted(){
        this.getEditData();
    },


    methods: {
        async getEditData(){
            this.isLoading = true
            try{
                const res = await BranchServices.getBranchById(this.data.id);
                if(res.status === 200){
                    this.isLoading = false
                    this.data.branch_name = res.data.branch_name
                    this.data.status = res.data.status
                }
            }catch(error){
                this.isLoading = false
                console.error('Failed to get branch',error)
            }
        },

        async updateBranch(){
            this.isUpdateLoading = true
            try{
                const res = await BranchServices.update(this.data.id,this.data);
                if(res.status === 200){
                        this.isUpdateLoading = false
                        Swal.fire({
                            title: "Successfully!",
                            text: "branch updated!",
                            icon: "success"
                        });
                        this.$router.push('/branches-list')
                    }else{
                        this.isUpdateLoading = false
                        Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: `${res.data.message}`,
                        });
                    }
            }catch(error){
                this.isUpdateLoading = false
                if(error.response.status === 422){
                    this.errors = error.response.data.errors
                }
            }
        }
    }
}
</script>