import { createStore } from "vuex"
import createPersistedState from "vuex-persistedstate";

const store = createStore({
    plugins: [createPersistedState()],
    
    state(){
        return{
            token: null,
            user: null,
        }
    },

    mutations: {
        setToken(state, token){
            state.token = token
        },

        clearToken(state){
            state.token = null
        },

        clearUser(state){
            state.user = null
        },

        setUser(state, user){
            state.user = user
        }
    },

    actions: {
        updateToken({commit}, token){
            commit('setToken', token)
        },

        deleteToken({commit}){
            commit('clearToken')
        },

        
        updateUser({commit}, user){
            commit('setUser', user);
        },
        
        deleteUser({commit}){
            commit('clearUser')
        },
    },

    getters: {
        token: (state) => state.token,
        currentUser: (state) => state.user,
        isAuthenticated: (state) => state.token,
    }
})

export default store

