<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card" style="margin-bottom: 5%;">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-10">
                                <span style="font-size: 18px;">View Loan Plan</span>
                            </div>
                            <div class="col-2">
                                <RouterLink to="/loan-plan-lists">
                                    <button class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                           
                    <pulse-loader 
                        :loading="isLoading" 
                        :color="color"
                        v-if="isLoading" 
                        style="margin-top: 50px; margin-left: 40%;"
                    />
                    <div class="card-body" v-else>
                        <div class="row">
                            <table class="table table-hover table-bordered table-sm table-responsive-sm">
                                <tbody>
                                    <tr>
                                        <th>Plan Name</th>
                                        <th>Plan Type</th>
                                        <th>Plan Amount</th>
                                    </tr>
    
                                    <tr>
                                        <td>{{ data.plan_name }}</td>
                                        <td>{{ data.plan_type }}</td>
                                        <td>{{ formatNumber(data.plan_amount) }}</td>
                                    </tr>
    
                                    <tr>
                                        <td colspan="3"></td>
                                    </tr>
    
                                    <tr>
                                        <th>Interest Rate</th>
                                        <th>File Charge Include</th>
                                        <th>File Charge</th>
                                    </tr>
                                    <tr>
                                        <td>{{ data.interest_rate }}</td>
                                        <td>{{ data.file_charge_include }}</td>
                                        <td>{{ data.file_charge_amount }}</td>
                                    </tr>
    
                                    <tr>
                                        <td colspan="3"></td>
                                    </tr>
    
                                    <tr>
                                        <th>Recovery Type</th>
                                        <th>Number of Payments</th>
                                        <th>Interest Type</th>
                                    </tr>
                                    <tr>
                                        <td>{{ data.recovery_type }}</td>
                                        <td>{{ data.number_of_payment }}</td>
                                        <td>{{ data.type_of_interest }}</td>
                                    </tr>
    
                                    <tr>
                                        <td colspan="3"></td>
                                    </tr>
    
                                    <tr>
                                        <th>Status</th>
                                        <th>Penalty Rate</th>
                                    </tr>
                                    <tr>
                                        <td><span class="badge badge-success">{{ data.status }}</span></td>
                                        <td>{{ data.penalty_rate }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from '../../services/Api';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
// import ContentLoader from '../../components/ContentLoader.vue';

export default{
    components: {
        PulseLoader,
        // ContentLoader
    },

    data(){
        return{
            data: {
                plan_name: '',
                plan_type: '',
                plan_amount: '',
                interest_rate: '',
                file_charge_include: '',
                file_charge_amount: '',
                number_of_payment: '',
                recovery_type: '',
                penalty_rate: 0,
                type_of_interest: '',
                status: '',
                id: this.$route.params.id,
            },
            isLoading: false,
            color: '#40aac7',
            errors: '',
        }
    },

    mounted(){
        this.fetchLoanPlan();
    },

    methods: {
        async fetchLoanPlan(){
            this.isLoading = true
            await Api().get(`loan-plans/${this.data.id}`)
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false;
                    this.data.plan_name = res.data[0].plan_name
                    this.data.plan_amount = res.data[0].plan_amount
                    this.data.plan_type = res.data[0].plan_type
                    this.data.interest_rate = res.data[0].interest_rate
                    this.data.file_charge_include = res.data[0].file_charge_include
                    this.data.file_charge_amount = res.data[0].file_charge_amount
                    this.data.number_of_payment = res.data[0].number_of_payment
                    this.data.recovery_type = res.data[0].recovery_type
                    this.data.type_of_interest = res.data[0].type_of_interest
                    this.data.status = res.data[0].status
                    this.data.penalty_rate = res.data[0].penalty_rate
                }
            }).then(error =>{
                if(error){
                    this.isLoading = false
                    console.error('Failed to fetch loan plan data!',error)
                }
            })
        },

        formatNumber(value){
            return new Intl.NumberFormat().format(value);
        }
    }
}
</script>