<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-md-10">
                                <span>Settings</span>
                            </div>
                            <div class="col-md-2">
                               
                            </div>
                        </div>
                    </div>

                    <pulse-loader 
                        :loading="isLoading" 
                        :color="color"
                        v-if="isLoading" 
                        style="margin-top: 50px; margin-left: 40%;"
                    />

                    <div class="card-body" v-else>
                        <div class="container container-border">
                            <div class="row pt-3">
                                 <div class="col-md-4">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <div class="input-group-prepend" style="height: 32px;">
                                                <span class="input-group-text" for="inputGroupSelect01">Select User</span>
                                            </div>
                                            <select class="form-control" v-model="data.userId" @change="fetchPermissions" style="height: 32px;">
                                                <option v-for="(user,i) in users" :key="i" :value="user.id">{{ user.full_name }}</option>
                                            </select>
                                        </div>
                                    </div>
                                 </div>
                                 <div class="col-md-2"></div>
                                 <div class="col-md-4">
                                    <span style="font-weight: 600;">User Permissions</span>
                                 </div>
                            </div>

                            <div class="container  mb-2" v-if="data.userId">
                                <table class="table table-hover table-bordered table-sm table-responsive-sm mt-2">
                                    <tbody>
                                        <tr>
                                            <td colspan="9" align="center"><span style="font-weight: 600;">Menu Access</span></td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.members">
                                                    <label class="form-check-label" for="defaultCheck1">
                                                    Members
                                                    </label>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox"  value="" id="defaultCheck1" v-model="permissions.loan_management">
                                                    <label class="form-check-label" for="defaultCheck1">
                                                        Loan Management
                                                    </label>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.disbursment">
                                                    <label class="form-check-label" for="defaultCheck1">
                                                        Disbursment
                                                    </label>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.collection">
                                                    <label class="form-check-label" for="defaultCheck1">
                                                        Collection
                                                    </label>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.accounting">
                                                    <label class="form-check-label" for="defaultCheck1">
                                                        Accounting
                                                    </label>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.expenses">
                                                    <label class="form-check-label" for="defaultCheck1">
                                                        Expenses
                                                    </label>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.user_access">
                                                    <label class="form-check-label" for="defaultCheck1">
                                                        User Access
                                                    </label>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.branches">
                                                    <label class="form-check-label" for="defaultCheck1">
                                                        Branches
                                                    </label>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.settings">
                                                    <label class="form-check-label" for="defaultCheck1">
                                                        Settings
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div class="row mt-4 pb-2">
                                    <div class="col-md-4" v-if="updating">
                                        <button type="button" disabled class="btn btn-info btn-sm">Saving...</button>
                                    </div>
                                    <div class="col-md-4" v-else>
                                        <button type="button" class="btn btn-info btn-sm" @click="updatePermissions">Save</button>
                                    </div>
                                    <pulse-loader 
                                        :loading="updating" 
                                        :color="color"
                                        v-if="updating" 
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from '../../services/Api';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Swal from 'sweetalert2';

export default {
    components: {
        PulseLoader
    },
    data(){
        return{
            data: {
                userId: ''
            },

            permissions: {
                members: false,
                loan_management: false,
                disbursment: false,
                collection: false,
                accounting: false,
                expenses: false,
                user_access: false,
                branches: false,
                settings: false,

            },

            isLoading: false,
            updating: false,
            users: [],
            color: '#40aac7',
        }
    },

    mounted(){
        this.fetchPermissions();
        this.fetchUsers();
    },

    methods: {
        async fetchUsers(){
            this.isLoading = true
            await Api().get('users')
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.users = res.data
                }
            }).catch(error =>{
                if(error){
                    console.log('Failed to get users')
                }
            })
        },

        async fetchPermissions(){
            this.isLoading = true
            await Api().get(`permissions/${this.data.userId}`)
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    res.data.forEach(permission =>{
                        if (Object.prototype.hasOwnProperty.call(this.permissions, permission.key)) {
                        this.permissions[permission.key] = permission.value;
                    }
                   })
                }
            }).catch(error =>{
                if(error){
                this.isLoading = false
                console.error('Failed to fetch permissions.',error)
                }
            });
        },

        async updatePermissions(){
           this.updating = true
           const updatedPermissions = Object.keys(this.permissions).map(key =>({
                key: key,
                value: this.permissions[key]
           }));
           await Api().post(`update-user-permissions/${this.data.userId}`,{permissions: updatedPermissions})
           .then(res =>{
            if(res.status === 200){
                this.updating = false
                Swal.fire({
                    title: "Successfully!",
                    text: `${res.data.message}`,
                    icon: "success"
                });
            }else{
                this.updating = false
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: `${res.data.error}`,
                });
            }
           }).catch(error =>{
            if(error){
                this.updating = false
                console.log('Failed to update user permissions.',error)
            }
           })
        }
    }
}
</script>

<style scoped>
.container-border{
    border:1px solid #e3e3e3 !important; 
}
</style>