<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card" style="margin-bottom: 5%;">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-10">
                                <span style="font-size: 18px;">Edit Loan Plan</span>
                            </div>
                            <div class="col-2">
                                <RouterLink to="/loan-plan-lists">
                                    <button class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                                </RouterLink>
                            </div>
                        </div>
                    </div>

                    <pulse-loader 
                        :loading="isLoading" 
                        :color="color"
                        v-if="isLoading" 
                        style="margin-top: 50px; margin-left: 40%;"
                    />

                    <div class="card-body" v-else>
                        <div class="row">
                            <div class="col-4">
                                <div class="form-group">
                                    <label>Plan Name</label>
                                    <input type="text" class="form-control" v-model="data.plan_name"/>
                                    <div v-if="errors.plan_name">
                                        <span class="text-danger">{{ errors.plan_name[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <label>Plan Type</label>
                                    <select class="form-control" v-model="data.plan_type">
                                        <option >Normal Plan</option>
                                        <option >Special Plan</option>
                                    </select>
                                    <div v-if="errors.plan_type">
                                        <span class="text-danger">{{ errors.plan_type[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <label>Plan Amount</label>
                                    <input type="text" class="form-control" v-model="data.plan_amount"/>
                                    <div v-if="errors.plan_amount">
                                        <span class="text-danger">{{ errors.plan_amount[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-4">
                                <div class="form-group">
                                    <label>Interest Rate</label>
                                    <input type="text" class="form-control" v-model="data.interest_rate"/>
                                    <div v-if="errors.interest_rate">
                                        <span class="text-danger">{{ errors.interest_rate[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <label>File Charge Include</label>
                                    <select class="form-control" v-model="data.file_charge_include">
                                        <option>Yes</option>
                                        <option>No</option>
                                    </select>
                                    <div v-if="errors.file_charge_include">
                                        <span class="text-danger">{{ errors.file_charge_include[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <label>File Charge</label>
                                    <input type="text" class="form-control" v-model="data.file_charge_amount"/>
                                    <div v-if="errors.file_charge_amount">
                                        <span class="text-danger">{{ errors.file_charge_amount[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-4">
                                <div class="form-group">
                                    <label>Number of Payments</label>
                                    <input type="text" class="form-control" v-model="data.number_of_payment"/>
                                    <div v-if="errors.number_of_payment">
                                        <span class="text-danger">{{ errors.number_of_payment[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <label>Recovery Type</label>
                                    <select class="form-control" v-model="data.recovery_type">
                                        <option :value="52">Weeky</option>
                                        <option :value="12">Monthly</option>
                                        <option :value="3">Quarterly</option>
                                        <option :value="6">Half Year</option>
                                    </select>
                                    <div v-if="errors.recovery_type">
                                        <span class="text-danger">{{ errors.recovery_type[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <label>Type of Interest</label>
                                    <select class="form-control" v-model="data.type_of_interest">
                                        <option>Fixed</option>
                                        <option>Reduced</option>
                                    </select>
                                    <div v-if="errors.type_of_interest">
                                        <span class="text-danger">{{ errors.type_of_interest[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-4">
                                <div class="form-group">
                                    <label>Penalty Rate</label>
                                    <input type="text" class="form-control" v-model="data.penalty_rate"/>
                                    <div v-if="errors.penalty_rate">
                                        <span class="text-danger">{{ errors.penalty_rate[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <label>Status</label>
                                    <select class="form-control" v-model="data.status">
                                        <option>Active</option>
                                        <option>InActive</option>
                                    </select>
                                    <div v-if="errors.status">
                                        <span class="text-danger">{{ errors.status[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-6" v-if="isUpdating">
                                <div class="btn btn-info btn-sm disabled" type="button">Updating...</div>
                            </div>
                            <div class="col-6" v-else>
                                <div class="btn btn-info btn-sm" @click="updateLoanPlan" type="button" >Update</div>
                            </div>
                            <pulse-loader 
                                :loading="isUpdating" 
                                :color="color"
                                v-if="isUpdating" 
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from '../../services/Api';
import Swal from 'sweetalert2';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

export default{
    components: {
        PulseLoader,
    },

    data(){
        return{
            data: {
                plan_name: '',
                plan_type: '',
                plan_amount: '',
                interest_rate: '',
                file_charge_include: '',
                file_charge_amount: '',
                number_of_payment: '',
                penalty_rate: '',
                recovery_type: '',
                type_of_interest: '',
                status: '',
                id: this.$route.params.id,
            },
            isLoading: false,
            isUpdating: false,
            color: '#40aac7',
            errors: '',
        }
    },

    mounted(){
        this.fetchLoanPlan();
    },

    methods: {
        async fetchLoanPlan(){
            this.isLoading = true
            await Api().get(`loan-plans/${this.data.id}`)
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false;
                    this.data.plan_name = res.data[0].plan_name
                    this.data.plan_amount = res.data[0].plan_amount
                    this.data.plan_type = res.data[0].plan_type
                    this.data.interest_rate = res.data[0].interest_rate
                    this.data.file_charge_include = res.data[0].file_charge_include
                    this.data.file_charge_amount = res.data[0].file_charge_amount
                    this.data.number_of_payment = res.data[0].number_of_payment
                    this.data.recovery_type = res.data[0].recovery_type
                    this.data.type_of_interest = res.data[0].type_of_interest
                    this.data.penalty_rate = res.data[0].penalty_rate
                    this.data.status = res.data[0].status
                }
            }).then(error =>{
                if(error){
                    this.isLoading = false
                    console.error('Failed to fetch loan plan data!',error)
                }
            })
        },

        async updateLoanPlan(){
            this.isUpdating = true
            await Api().put(`loan-plans/${this.data.id}`,this.data)
            .then(res =>{
                if(res.status === 200){
                    this.isUpdating = false
                    Swal.fire({
                        title: "Good job!",
                        text: "Loan plan updated successfully!",
                        icon: "success"
                    });
                    this.$router.push('/loan-plan-lists')
                }else{
                    this.isUpdating = false
                    Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: `${res.data.error}`,
                    });
                }
            }).catch(error =>{
                if(error){
                    if(error.response.status === 422){
                        this.isUpdating = false
                        this.errors = error.response.data.errors
                    }
                }
            })
        }
    }
}
</script>