<template>
  <!-- Navbar -->
  <nav class="main-header navbar navbar-expand navbar-white navbar-light fixed-top">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button">
          <i class="fas fa-bars" style="color: #1d2363 !important;"></i>
          
        </a>
      </li>
    </ul>

    <ul class="navbar-nav ml-auto">
      <li class="nav-item dropdown">
        <a id="dropdownSubMenu1" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="nav-link dropdown-toggle" style="color: #1d2363"><b>{{ branch }}</b></a>
        <ul aria-labelledby="dropdownSubMenu1" class="dropdown-menu border-0 shadow">
          <li>
            <button v-for="(branch, i) in branches" :key="i" @click="switchBranch(branch.id)" type="button" class="dropdown-item">{{ branch.branch_name }}</button>
          </li>
        </ul>
      </li>
    </ul>

    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <!-- <li>
        <pulse-loader 
          :loading="isLoading" 
          :color="color"
          v-if="isLoading" 
          style="margin-top: 10px;"
        />
      </li> -->
      <li class="nav-item dropdown">
          <a id="dropdownSubMenu1" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="nav-link dropdown-toggle" style="color: #1d2363"><b>{{ currentUser.full_name }}</b></a>
          <ul aria-labelledby="dropdownSubMenu1" class="dropdown-menu border-0 shadow">
            <li><button @click="logout" type="button" class="dropdown-item">LogOut</button></li>
          </ul>
      </li>
    </ul>
  </nav>
  <!-- /.navbar -->
</template>

<script>
import Api from '../services/Api';
import auth from '../services/auth';
// import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import BranchServices from '../services/BranchServices';
import { mapActions, mapGetters } from 'vuex';
export default {

  components: {
    // PulseLoader,
  },

  data(){
    return{
      user: '',
      isLoading: false,
      color: '#40aac7',
      branch: '',
      branches: []
    }
  },

  created(){
    // this.getCurrentUser();
    this.getUserBranch();
    this.getBranches();
  },

  computed: {
    ...mapGetters(['currentUser'])
  },

  methods: {
    ...mapActions(['deleteToken','deleteUser']),
    async logout() {
      this.isLoading = true
      try {
        await auth.logout();
        this.isLoading = false
        this.deleteToken()
        this.deleteUser()
        // localStorage.removeItem('token');
        this.$router.push('/login');
      } catch (error) {
        this.isLoading = false
        console.error('Logout failed', error);
      }
    },

    // async getCurrentUser(){
    //   this.isLoading = true
    //   try{
    //     this.user = await auth.getUser();
    //     this.isLoading = false
    //   }catch(error){
    //     this.isLoading = false
    //     console.error('Failed to get user info', error)
    //   }
    // },

    async getUserBranch(){
      this.isLoading = true
      await Api().get('get-user-branchId')
      .then(res =>{
        if(res.status === 200){
          this.isLoading = false
          this.branch = res.data[0].branch_name
        }
      }).catch(error =>{
        this.isLoading = false;
        console.error('failed to get user branch',error);
      })
    },

    async getBranches(){
      this.isLoading = true
      try{
        const response = await BranchServices.getBranchData();
        if(response.status === 200){
          this.isLoading = false
          this.branches = response.data
        }
      }catch(error){
        console.error('Failed to get branches',error)
      }
    },

    async switchBranch(branchId){
      this.isLoading = true
      await Api().post('switch-branch',{branchId: branchId})
      .then(res => {
        if(res.status === 200){
          this.isLoading = false
        }
      }).catch(error =>{
        if(error){
          this.isLoading = false
          console.error('Failed to switch branch', error)
        }
      })
    }
  },
}
</script>

<style>

</style>