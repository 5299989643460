import Api from "./Api"


export default {
    async regiserBranch(branch_name){
        const response = await Api().post('branches',{
            branch_name: branch_name,
        });
        return response;
    },
    
    async  getBranchData(){
        const response = await Api().get('branches');
        return response;
    },

    async getBranchById(id){
        const response = await Api().get(`branches/${id}`);
        return response;
    },

    async update(id, data){
        const response = await Api().put(`branches/${id}`,data)
        return response;
    },

    async delete(id){
        const response = await Api().delete(`branches/${id}`)
        return response;
    }
}