<template>
    <div class="container-fluid">
        <ContentLoader v-if="isLoading"/>
        <div class="row" v-else>
            <div class="col-md-12">
                <div class="card" style="margin-bottom: 5%;">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-md-10">
                                <span style="font-size: 18px;">Pending Loan Lists</span>
                            </div>
                            <div class="col-md-2">
                                <RouterLink to="/approved-loan">
                                    <button class="btn btn-info btn-sm"><i class="fas fa-wallet"></i> Approved Loans</button>
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <!-- <pulse-loader 
                            :loading="isLoading" 
                            :color="color"
                            v-if="isLoading" 
                            style="margin-top: 50px; margin-left: 40%;"
                        /> -->

                        <div class="row">
                            <div class="col-12">
                                <table id="example1" class="table table-hover table-bordered table-striped table-sm table-responsive-sm">
                                    <thead>
                                        <tr>
                                            <td>Date</td>
                                            <td>Plan Name</td>
                                            <td>Plan Emi Type</td>
                                            <td>Pl Amount</td>
                                            <td>Member</td>
                                            <td>User</td>
                                            <td>Status</td>
                                            <!-- <td>View</td>
                                            <td>Edit</td> -->
                                            <td>Approve</td>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr v-for="(loan, i) in loans" :key="i">
                                            <td>{{ loan.date }}</td>
                                            <td>{{ loan.loan_plans.plan_name }}</td>
                                            <td>{{ loan.loan_plans.recovery_type }}</td>
                                            <td>{{ formatNumber(loan.loan_plans.plan_amount) }}</td>
                                            <td>{{ loan.members.first_name }} {{ loan.members.middle_name }} {{ loan.members.last_name }}</td>
                                            <td>{{ loan.user_name }}</td>
                                            <td><span class="badge badge-success">{{ loan.status }}</span></td>
                                            <td>
                                                <RouterLink :to="{name: 'approve-loan',params:{id: loan.id}}">
                                                    <button class="btn btn-info btn-sm"><i class="fa fa-edit"></i></button>
                                                </RouterLink>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Api from '../../services/Api';
import ContentLoader from '../../components/ContentLoader.vue';

export default{
    components: {
        // PulseLoader,
        ContentLoader
    },

    data(){
        return{
            isLoading: false,
            loans: [],
            color: '#40aac7',
        }
    },

    mounted(){
        this.fetchLoan();
    },

    methods: {
        async fetchLoan(){
            this.isLoading = true
            await Api().get('pending-loan-lists')
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.loans = res.data
                }
            }).catch(error =>{
                this.isLoading = false
                console.error('Failed to get loan plan information',error)
            })
        },

        formatNumber(value){
            return new Intl.NumberFormat().format(value);
        }
    }
}
</script>

<style scoped>
    #example1{
        border-collapse: collapse; 
        border: 1px solid #839E99;
        background: #f1f8ee;  
        color: #033;
    }
</style>