<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card" style="margin-bottom: 5%;">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-10">
                                <span style="font-size: 18px;">New Member Informations</span>
                            </div>
                            <div class="col-2">
                                <RouterLink to="/individual-member-list">
                                    <button class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                                </RouterLink>
                            </div>
                        </div>
                    </div>
    
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <span class="span-style">First Name:</span>
                                    <input type="text" class="form-control size mt-2" v-model="data.first_name" />
                                    <div v-if="errors.first_name">
                                        <span class="text-danger">{{ errors.first_name[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <span class="span-style">Middle Name:</span>
                                    <input type="text" class="form-control size mt-2" v-model="data.middle_name" />
                                    <div v-if="errors.middle_name">
                                        <span class="text-danger">{{ errors.middle_name[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <span class="span-style">Last Name:</span>
                                    <input type="text" class="form-control size mt-2" v-model="data.last_name" />
                                    <div v-if="errors.last_name">
                                        <span class="text-danger">{{ errors.last_name[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <span class="span-style">Phone No:</span>
                                    <input type="text" class="form-control size mt-2" v-model="data.phone" />
                                    <div v-if="errors.phone">
                                        <span class="text-danger">{{ errors.phone[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <span class="span-style">Email:</span>
                                    <input type="text" class="form-control size mt-2" v-model="data.email" placeholder="Optional"/>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <span class="span-style">Nida No:</span>
                                    <input type="text" class="form-control size mt-2" v-model="data.nida_no" />
                                    <div v-if="errors.nida_no">
                                        <span class="text-danger">{{ errors.nida_no[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div class="row">
                            <div class="col-md-4">
                                <span class="span-style">D.O.B</span><br>
                                <date-picker v-model:value="data.dob" value-type="YYYY-MM-DD" />
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <span class="span-style">Address:</span>
                                    <input type="text" class="form-control size mt-2" v-model="data.address"/>
                                    <div v-if="errors.address">
                                        <span class="text-danger">{{ errors.address[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <span class="span-style">Collateral:</span>
                                    <input type="text" class="form-control size mt-2" v-model="data.collateral"/>
                                    <div v-if="errors.collateral">
                                        <span class="text-danger">{{ errors.collateral[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <span class="span-style">Select File:</span>
                                    <input type="file" class="form-control size mt-2"/>
                                </div>
                            </div>
                        </div>
    
                        <div class="row mt-2">
                            <div class="col-6">
                                <h5>Referee Informations</h5>
                            </div>
                        </div>
    
                        <div class="row mt-2">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <span class="span-style">First Name:</span>
                                    <input type="text" class="form-control size mt-2" v-model="data.referee_fname" />
                                    <div v-if="errors.referee_fname">
                                        <span class="text-danger">{{ errors.referee_fname[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <span class="span-style">Middle Name:</span>
                                    <input type="text" class="form-control size mt-2" v-model="data.referee_middle_name" />
                                    <div v-if="errors.referee_middle_name">
                                        <span class="text-danger">{{ errors.referee_middle_name[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <span>Last Name:</span>
                                    <input type="text" class="form-control size mt-2" v-model="data.referee_lname" />
                                    <div v-if="errors.referee_lname">
                                        <span class="text-danger">{{ errors.referee_lname[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <span class="span-style">Phone No:</span>
                                    <input type="text" class="form-control size mt-2" v-model="data.referee_phone" />
                                    <div v-if="errors.referee_phone">
                                        <span class="text-danger">{{ errors.referee_phone[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <span class="span-style">Address:</span>
                                    <input type="text" class="form-control size mt-2" v-model="data.referee_address" />
                                    <div v-if="errors.referee_address">
                                        <span class="text-danger">{{ errors.referee_address[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <span class="span-style">Nida No:</span>
                                    <input type="text" class="form-control size mt-2" v-model="data.referee_nida_no" />
                                    <div v-if="errors.referee_nida_no">
                                        <span class="text-danger">{{ errors.referee_nida_no[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-6" v-if="isLoading">
                                <div class="btn btn-info btn-sm disabled" type="button">Submit</div>
                            </div>
                            <div class="col-6" v-else>
                                <div class="btn btn-info btn-sm" @click="createNewMember" type="button">Submit</div>
                            </div>
                            <pulse-loader 
                                :loading="isLoading" 
                                :color="color"
                                v-if="isLoading" 
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import Api from '../../services/Api';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Swal from 'sweetalert2';

export default{
    components: {
        DatePicker,
        PulseLoader,
    },

    data(){
        return{
            data: {
                first_name: null,
                middle_name: null,
                last_name: null,
                phone: null,
                email: null,
                nida_no: null,
                dob: new Date(),
                address: null,
                collateral: null,
                referee_fname: null,
                referee_middle_name: null,
                referee_lname: null,
                referee_phone: null,
                referee_address: null,
                referee_nida_no: null,
            },
            isLoading: false,
            color: '#40aac7',
            errors: '',
        }
    },


    methods: {
        async createNewMember(){
            this.isLoading = true
            await Api().post('members',this.data)
            .then(res =>{
                if(res.status === 200){
                this.isLoading = false
                Swal.fire({
                    title: "Good job!",
                    text: "New member created!",
                    icon: "success"
                });
                this.$router.push('/individual-member-list');
                this.data.first_name = null
                this.data.last_name = null
                this.data.middle_name = null
                this.data.address = null
                this.data.email = null
                this.data.dob = null
                this.data.nida_no = null
                this.data.collateral = null
                this.data.phone = null
                this.data.referee_address = null
                this.data.referee_fname = null
                this.data.referee_nida_no = null
                this.data.referee_lname = null
                this.data.referee_middle_name = null
                this.data.referee_phone = null
            }
            }).catch(error =>{
                if(error.response.status === 422){
                    this.isLoading = false
                    this.errors = error.response.data.errors
                }
            })
        }
    }
}
</script>

<style>
.size{
    height: 34px;
    border-color: aliceblue;
}

.span-style{
    margin-left: 2px;
    font-weight: 500;
}

.span-style{
    margin-left: 2px;
    font-weight: 500;
}
</style>