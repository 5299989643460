<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card" style="margin-bottom: 5%;">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-10">
                               <span style="font-weight: 500;">New Bank Branch Information</span>
                            </div>
                            <div class="col-2">
                                <RouterLink to="/bank-account-list">
                                    <button class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="container-fluid " style="border-style: solid; border-width: 1px; border-color: aliceblue;">
                            <div class="row mt-2 justify-content-center">
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Branch Name*</span>
                                        <input type="text" class="form-control size mt-2" v-model="data.branch_name"/>
                                        <div v-if="errors.branch_name">
                                        <span class="text-danger">{{ errors.branch_name[0] }}*</span>
                                    </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Select Bank*</span>
                                        <select class="form-control size mt-2" v-model="data.bankId">
                                            <option v-for="(bank, i) in banks" :key="i" :value="bank.id">{{ bank.bank_name }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row justify-content-center">
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Branch Code*</span>
                                        <input type="text" class="form-control size mt-2" v-model="data.branch_code" placeholder="Optional"/>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Address*</span>
                                        <input type="text" class="form-control size mt-2" v-model="data.address" placeholder="Optional"/>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div class="col-2"></div>
                                <div class="col-4" v-if="isLoading">
                                    <div class="btn btn-info btn-sm disabled" type="button">Submiting...</div>
                                </div>
                                <div class="col-4" v-else>
                                    <div class="btn btn-info btn-sm" type="button" @click="createNewBankBranch">Submit</div>
                                </div>
                                <pulse-loader 
                                    :loading="isLoading" 
                                    :color="color"
                                    v-if="isLoading" 
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import 'vue-datepicker-next/index.css';
import Api from '../../services/Api';
import Swal from 'sweetalert2';

export default{
    components: {
        PulseLoader,
    },

    data(){
        return{
            data: {
              branch_name: '',
              branch_code: '',
              address: '',
              bankId: ''
            },

            isLoading: false,
            color: '#40aac7',
            errors: '',
            banks: []
        }
    },

    mounted(){
        this.fetchBank();
    },

    methods: {
       async createNewBankBranch(){
        this.isLoading = true
        await Api().post('bank-branches',this.data)
        .then(res =>{
            if(res.status === 200){
                this.isLoading = false
                Swal.fire({
                    title: "Good job!",
                    text: `${res.data.message}`,
                    icon: "success"
                });
                this.data.branch_name = ''
                this.data.branch_code = ''
                this.data.branch_name = ''
                this.data.address = ''
                this.data.bankId = ''
                this.$router.push('/bank-branch-list')
            }
        }).catch(error => {
            if(error.response.status === 422){
                this.isLoading = false
                this.errors = error.response.data.errors
            }
        })
       },

       async fetchBank(){
        this.isLoading = true
        await Api().get('banks')
        .then(res =>{
            if(res.status === 200){
                this.isLoading = false
                this.banks = res.data
            }
        }).catch(error =>{
            if(error) return console.log('Failed to get bank informations!')
        })
       }
    }
}
</script>

<style>
.size{
    height: 34px;
    border-color: aliceblue;
}

.span-style{
    margin-left: 2px;
    font-weight: 500;
}
</style>