<template>
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-8" style="margin-bottom: 5%;">
                <div class="card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-10">
                                <span style="font-size: 18px;">Update User Informations</span>
                            </div>
                            <div class="col-2">
                                <RouterLink to="/users-list">
                                    <button class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                                </RouterLink>
                            </div>
                        </div>
                    </div>

                    <pulse-loader 
                        :loading="isLoading" 
                        :color="color"
                        v-if="isLoading" 
                        style="margin-top: 50px; margin-left: 40%;"
                    />
    
                    <div class="card-body" v-else>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="first_name">Full Name:</label>
                                    <input type="text" class="form-control" v-model="data.full_name" />
                                    <div v-if="errors.full_name">
                                        <span class="text-danger">{{ errors.full_name[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="middle_name">Phone:</label>
                                    <input type="text" class="form-control" v-model="data.phone" />
                                    <div v-if="errors.phone">
                                        <span class="text-danger">{{ errors.phone[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="last_name">Email:</label>
                                    <input type="text" class="form-control" v-model="data.email" />
                                    <div v-if="errors.email">
                                        <span class="text-danger">{{ errors.email[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Select Branch</label>
                                    <select class="form-control select2" v-model="data.branchId">
                                        <option >Select Branch</option>
                                        <option v-for="(barnch, i) in branches" :key="i" :value="barnch.id">{{ barnch.branch_name }}</option>
                                    </select>
                                    <div v-if="errors.branchId">
                                        <span class="text-danger">{{ errors.branchId[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Select Status</label>
                                    <select class="form-control select2" v-model="data.status">
                                        <option >Select Status</option>
                                        <option >Active</option>
                                        <option >In Active</option>
                                    </select>
                                    <div v-if="errors.status">
                                        <span class="text-danger">{{ errors.status[0] }}*</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-6" v-if="isUpdating">
                                <div class="btn btn-info btn-sm disabled" type="button">Updating...</div>
                            </div>
                            <div class="col-6" v-else>
                                <div class="btn btn-info btn-sm" @click="updateUser" type="button">Update</div>
                            </div>
                            <pulse-loader 
                                :loading="isUpdating" 
                                :color="color"
                                v-if="isUpdating" 
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import 'vue-datepicker-next/index.css';
import Api from '../../services/Api';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Swal from 'sweetalert2';
import BranchServices from '../../services/BranchServices';

export default{
    components: {
        PulseLoader,
    },

    data(){
        return{
            
            data: {
               full_name: null,
               email: null,
            //    designation: null,
               branchId: null,
               phone: null,
               status: 'Active',
               id: this.$route.params.id,
            },
            isLoading: false,
            isUpdating: false,
            color: '#40aac7',
            users: [],
            branches: [],
            errors: ''
        }
    },

    mounted(){
        this.getBranches();
        this.getEditData();
    },

    methods: {

        async getBranches(){
            this.isLoading = true
            try{
                const res = await BranchServices.getBranchData();
                if(res.status === 200){
                    this.isLoading = false
                    this.branches = res.data
                }
            }catch(error){
                this.isLoading = false
                console.error('Failed to get branche data',error)
            }
        },

        async getEditData(){
            this.isLoading = true
            await Api().get(`users/${this.data.id}`)
            .then(res =>{
                try{
                    if(res.status === 200){
                       this.isLoading
                       this.data.full_name = res.data.full_name
                       this.data.email = res.data.email
                    //    this.data.designation = res.data.designation
                       this.data.phone = res.data.phone
                       this.data.status = res.data.status
                       this.data.branchId = res.data.branchId
                    }
                }catch(error){
                    this.isLoading
                    console.log(error)
                }
            })
        },

        async updateUser(){
            this.isUpdating = true
            await Api().put(`users/${this.data.id}`,this.data)
            .then(res =>{
                if(res.status === 200){
                    this.isUpdating = false
                    Swal.fire({
                        title: "Successfully!",
                        text: "user updated!",
                        icon: "success"
                    });
                    this.$router.push('/users-list')
                }else{
                    this.isUpdating = false
                    Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: `${res.data.message}`,
                    });
                }
            }).catch(error =>{
                this.isUpdating = false
                if(error.response.status === 422){
                    this.errors = error.response.data.errors
                }
            })
        }
    }
}
</script>