<template>
    <div class="container-fluid">
        <!-- <ContentLoader v-if="isLoading"/> -->
        <div class="row">
            <div class="col-md-12" style="margin-bottom: 5%;">
                <div class="card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-10">
                                <span style="font-weight: 600;">Accounts Statement</span>
                            </div>
                            <div class="col-2">
                                <RouterLink to="/bank-account-list">
                                    <button class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                   
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <date-picker v-model:value="data.startDate" 
                                        value-type="YYYY-MM-DD" 
                                        placeholder="Start Date"
                                        @change="onStartDateChange"
                                    />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <date-picker v-model:value="data.endDate" 
                                    value-type="YYYY-MM-DD" 
                                    placeholder="End Date"
                                    @change="onEndDateChange"
                                />
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-group">
                                    <div class="input-group">
                                        <div class="input-group-prepend" style="height: 32px;">
                                            <span class="input-group-text" for="inputGroupSelect01">Select Account</span>
                                        </div>
                                        <select class="form-control" style="height: 32px;" v-model="data.account_name" @change="onAccounrChange">
                                            <option v-for="(account, i) in accounts" :key="i" :value="account.id">{{ account.account_name }} {{ account.account_no }} {{ account.banks.bank_name }}</option>
                                        </select>
                                        <div v-if="errors.account_name">
                                            <span class="text-danger">{{ errors.account_name[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!-- <span style="font-size: 16px; margin-bottom: 5px;">Largers</span> -->
                            <pulse-loader 
                                :loading="isLoading" 
                                :color="color"
                                v-if="isLoading" 
                                style="margin-top: 60px; margin-left: 40%;"
                            />
                            <table v-else id="table-content" class="table table-hover table-sm table-responsive-sm">
                              <tbody>
                                <tr>
                                    <th>Date</th>
                                    <th>Member</th>
                                    <th>LoanId</th>
                                    <th>Description</th>
                                    <th>Particular</th>
                                    <th>Dr</th>
                                    <th>Cr</th>
                                </tr>

                                <tr v-for="(statement, i) in statements" :key="i">
                                    <td>{{ statement.date }}</td>
                                    <td>
                                        <RouterLink :to="{name: 'member-loans-list',params:{id: statement.memberId}}">
                                            <span style="color: black;">{{ statement.member_name }}</span>
                                        </RouterLink>
                                    </td>
                                    <td>{{ statement.loan_code }}</td>
                                    <td>{{ statement.description }}</td>
                                    <td>{{ statement.transaction_type }}</td>
                                    <td>{{ formatNumber(statement.dr) }}</td>
                                    <td>{{ formatNumber(statement.cr) }}</td>
                                </tr>

                                <tr>
                                    <td colspan="4"><b></b></td>
                                    <td><b>Total</b></td>
                                    <td class="double-line">
                                        <b>{{ formatNumber(data.total_dr) }}</b>
                                    </td>
                                    <td class="double-line">
                                        <b>{{ formatNumber(data.total_cr) }}</b>
                                    </td>
                                </tr>
                              </tbody>
                            </table>
                        </div>

                        <div class="row">
                            <div class="col-4 mb-3" v-if="isPrinting">
                                <button class="btn btn-info btn-sm hide-on-pdf" disabled><i class="fas fa-print"></i> Printing...</button>
                            </div>
                            <div class="col-4 mb-3" v-else>
                                <button class="btn btn-info btn-sm hide-on-pdf" @click="printPdf"><i class="fas fa-print"></i> Print</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import 'vue-datepicker-next/index.css';
import Api from '../../services/Api';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
// import ContentLoader from '../../components/ContentLoader.vue';
import DatePicker from 'vue-datepicker-next';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export default{
    components: {
        DatePicker,
        PulseLoader,
        // ContentLoader
    },

    data(){
        return{
            
            data: {
               total_interest: '',
               total_dr: '',
               total_cr: '',
               startDate: '',
               endDate: '',
               account_name: ''
            },
            
            isLoading: false,
            isUpdateLoading: false,
            color: '#40aac7',
            statements: [],
            accounts: [],
            errors: '',
            isPrinting: false
        }
    },

    mounted(){
      this.fetchBankAccounts();
    },


    methods: {
        onEndDateChange(){
            if(this.data.endDate){
                this.getStatements();
            }
        },

        onStartDateChange(){
            if(this.data.startDate){
                this.getStatements();
            }
        },

        onAccounrChange(){
            if(this.data.account_name){
                this.getStatements();
            }
        },

        async getStatements(){
            if(!this.data.startDate || !this.data.endDate){
                return;
            }
            this.isLoading = true
            await Api().post('get-account-statements',this.data)
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.statements = res.data.statements
                    this.data.total_dr = res.data.total_dr
                    this.data.total_cr = res.data.total_cr
                    this.data.total_interest = res.data.total_interest
                    console.log(res.data)
                }
            }).catch(error =>{
                if(error){
                    console.log('Failed to get statements')
                }
            })
        },

        async fetchBankAccounts(){
            this.isLoading = true
            await Api().get('bank-accounts')
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.accounts = res.data
                }
            }).catch(error => {
                if(error){
                    console.log('Failed to get bank information!')
                }
            })
        },

        async printPdf(){
            // Hide buttons
            this.isPrinting = true
            const buttons = document.querySelectorAll('.hide-on-pdf');
            buttons.forEach(button => button.style.display = 'none');

            const element = document.getElementById('table-content');
            const canvas = await html2canvas(element);
            const imgData = canvas.toDataURL('image/png');
            
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'pt',
                format: 'a4'
            });

            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
            
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      
            // Convert the PDF to a Blob to create a URL for preview
            const pdfBlob = pdf.output('blob');
            const pdfUrl = URL.createObjectURL(pdfBlob);

            // Open the PDF in a new window or tab for preview
            const pdfWindow = window.open(pdfUrl, '_blank');

            if (pdfWindow) {
                this.isPrinting = false
                // Automatically print the PDF after opening the preview
                pdfWindow.addEventListener('load', () => {
                // pdfWindow.print();
                });
            } else {
                this.isLoading = false
                // If the window couldn't be opened (e.g., due to pop-up blockers)
                alert('Please allow pop-ups to preview and print the PDF.');
            }

            // Show buttons again
            buttons.forEach(button => button.style.display = 'inline-block');
        },

        formatNumber(value){
            return new Intl.NumberFormat().format(value);
        }
    }
}
</script>

<style scoped>
#container-border{
    border-style: solid;
    border-width: 1px;
    border-color: aliceblue;
}

.table {
    border-style: solid !important;
    border-width: 1px !important;
    border-color: aliceblue;
    background-color: aliceblue;
}

.table tbody tr td{
    border: none !important;
    font-size: 13px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.table tbody tr th{
    background-color: #e7e7f4;;
    font-size: 13px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.double-line{
   position: relative;
   height: 50px;
}

.double-line::before, .double-line::after{
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    border-top: 1px solid black;
}

.double-line::before{
    bottom: 50%;
}
</style>