<template>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
            <div class="card" style="margin-bottom: 5%;">
                <div class="card-header">
                    <div class="row">
                        <div class="col-md-10">
                            <h3 class="card-title">Branches List</h3>
                        </div>
                        <div class="col-md-2">
                            <RouterLink to="/create-branch">
                                <button type="button" class="btn btn-sm btn-info">New Branch</button>
                            </RouterLink>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <pulse-loader 
                        :loading="isLoading" 
                        :color="color"
                        v-if="isLoading" 
                        style="margin-top: 50px; margin-left: 40%;"
                    />
                    <div class="row" v-else>
                        <div class="col-12">
                            <table id="example1" class="table table-hover table-bordered table-striped table-sm">
                                <thead>
                                <tr>
                                <th>Full Name</th>
                                <th>Status</th>
                                <th>Edit</th>
                                <th>Delete</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(branch, i) in branches" :key="i">
                                    <td>{{ branch.branch_name }}</td>
                                    <td ><span class="badge badge-success">{{ branch.status }}</span></td>
                                    <td>
                                        <RouterLink :to="{name: 'edit-branches',params: {id: branch.id}}">
                                            <button class="btn btn-info btn-sm"><i class="fa fa-edit"></i></button>
                                        </RouterLink>
                                    </td>
                                    <td>
                                        <button class="btn btn-danger btn-sm" @click="deleteConfirm(branch.id)"><i class="fa fa-trash"></i></button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
</template>


<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Swal from 'sweetalert2';
import BranchServices from '../../services/BranchServices';

export default {
    components: {
        PulseLoader,
    },
    data() {
        return {
            branches: [],
            deleteIdex: -1,
            isLoading: false,
            color: '#40aac7'
        }
    },

    mounted(){
        this.fetchData()
    },

    methods: {
        async fetchData(){
            this.isLoading = true
            try{
                const response = await BranchServices.getBranchData();
                if(response.status === 200){
                    this.isLoading = false
                    this.branches = response.data
                }else{
                    this.isLoading = false
                    console.log('Failed to get branch data')
                }
            }catch(error){
                this.isLoading = false
                console.error('Failed to get branch data',error)
            }
        },


        deleteConfirm(id){
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: "btn btn-success",
                    cancelButton: "btn btn-danger"
                },
                buttonsStyling: false
            });

            swalWithBootstrapButtons.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, cancel!",
            reverseButtons: true
            }).then((result) => {
            if (result.isConfirmed) {
                this.deleteBranch(id);
                this.branches.splice(this.deleteIdex, 1)
                swalWithBootstrapButtons.fire({
                title: "Deleted!",
                text: "Branch has been deleted.",
                icon: "success"
                });
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire({
                title: "Cancelled",
                text: "Your branch is safe",
                icon: "error"
                });
            }
            });
        },

        async deleteBranch(id){
            this.isLoading = true
            try{
                const response = await BranchServices.delete(id);
                if(response.status === 200){
                    this.isLoading = false
                }
            }catch(error){
                console.error('Failed to delete branch')
            }
        },
    }
}
</script>

<style scoped>
    #example1{
        border-collapse: collapse; 
        border: 1px solid #839E99;
        background: #f1f8ee;  
        color: #033;
    }
</style>