<template>
    <div class="container-fluid">
        <ContentLoader v-if="isLoading"/>
        <div class="row" v-else>
            <div class="col-md-3" style="margin-bottom: 5%;">
                <div class="card card-info card-outline">
                    <div v-if="!isLoading" class="card-body box-profile">
                        <div class="text-center">
                        <img class="profile-user-img img-fluid img-circle"
                            src="../../assets//img//depima_logo.png"
                            alt="User profile picture">
                        </div>
                        <h3 class="profile-username text-center">{{ data.first_name }} {{ data.middle_name }} {{ data.last_name }}</h3>
                        <ul class="list-group list-group-unbordered mb-3">
                        <li class="list-group-item">
                            <b>Mobile Number</b> <a class="float-right">{{ data.phone }}</a>
                        </li>
                        <li class="list-group-item">
                            <b>Nida Number</b> <a class="float-right">{{ data.nida_no }}</a>
                        </li>
                        <li class="list-group-item">
                            <b>Birth Date</b> <a class="float-right">{{ data.dob }}</a>
                        </li>
                        <li class="list-group-item">
                            <b>Email</b> <a class="float-right">{{ data.email }}</a>
                        </li>
                        <li class="list-group-item">
                            <b>Address</b> <a class="float-right">{{ data.address }}</a>
                        </li>
                        </ul>
                        <RouterLink :to="{name: 'member-loans-list',params:{id:this.id}}">
                            <button class="btn btn-info btn-sm btn-block">Loan Details</button>
                        </RouterLink>
                        <!-- <a href="#" class="btn btn-info btn-sm btn-block"><b>Loan Details</b></a> -->
                    </div>
                </div>
            </div>
            
            <div class="col-md-9" style="margin-bottom: 5%;">
                <div class="card card-info card-outline">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-10">
                                
                            </div>
                            <div class="col-2">
                                <RouterLink to="/individual-member-list">
                                    <button class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                    <!-- <pulse-loader 
                        :loading="isLoading" 
                        :color="color"
                        v-if="isLoading" 
                        style="margin-top: 60px; margin-left: 40%;"
                    /> -->
                    <div class="card-body">
                        <div class="row">
                            <table id="example1" class="table table-hover table-bordered table-sm table-responsive-sm">
                                <tbody>
                                    <tr>
                                        <td colspan="3" align="center"><span style="font-weight: 600;">Member Informations</span></td>
                                    </tr>
                                    <tr>
                                        <th>First Name</th>
                                        <th>Middle Name</th>
                                        <th>Last Name</th>
                                    </tr>
                                    <tr>
                                        <td>{{ data.first_name }}</td>
                                        <td>{{ data.middle_name }}</td>
                                        <td>{{ data.last_name }}</td>
                                    </tr>

                                    <tr >
                                        <td colspan="3"></td>
                                    </tr>

                                    <tr>
                                        <th>Phone </th>
                                        <th>Email</th>
                                        <th>Nida No</th>
                                    </tr>
                                    <tr>
                                        <td>{{ data.phone }}</td>
                                        <td>{{ data.email }}</td>
                                        <td>{{ data.nida_no }}</td>
                                    </tr>

                                    <tr >
                                        <td colspan="3" align="center"><span style="font-weight: 600;">Referee Informations</span></td>
                                    </tr>
                                    <tr>
                                        <th>First Name</th>
                                        <th>Middle Name</th>
                                        <th>Last Name</th>
                                    </tr>
                                    <tr>
                                        <td>{{ data.referee_fname }}</td>
                                        <td>{{ data.referee_middle_name }}</td>
                                        <td>{{ data.referee_lname }}</td>
                                    </tr>
                                    
                                    <tr>
                                        <td colspan="3"></td>
                                    </tr>
                                    <tr>
                                        <th>Phone Number</th>
                                        <th>Address</th>
                                        <th>Nida No</th>
                                    </tr>
                                    <tr>
                                        <td>{{ data.referee_phone }}</td>
                                        <td>{{ data.referee_address }}</td>
                                        <td>{{ data.referee_nida_no }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import Api from '../../services/Api';
// import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import ContentLoader from '../../components/ContentLoader.vue';

export default{
    components: {
        // DatePicker,
        // PulseLoader,
        ContentLoader
    },

    data(){
        return{
            
            data: {
                first_name: null,
                middle_name: null,
                last_name: null,
                phone: null,
                email: null,
                nida_no: null,
                dob: new Date(),
                address: null,
                collateral: null,
                referee_fname: null,
                referee_middle_name: null,
                referee_lname: null,
                referee_phone: null,
                referee_address: null,
                referee_nida_no: null,
            },
            
            isLoading: false,
            isUpdateLoading: false,
            id: this.$route.params.id,
            color: '#40aac7'
        }
    },

    mounted(){
        this.getEditData()
    },


    methods: {
        async getEditData(){
            this.isLoading = true
            await Api().get(`members/${this.id}`)
            .then(res =>{
                try{
                    if(res.status === 200){
                        this.isLoading = false
                        this.data.first_name = res.data.first_name
                        this.data.middle_name = res.data.middle_name
                        this.data.last_name = res.data.last_name
                        this.data.nida_no = res.data.nida_no
                        this.data.email = res.data.email
                        this.data.address = res.data.address
                        this.data.collateral = res.data.collateral
                        this.data.phone = res.data.phone
                        this.data.dob = res.data.dob
                        this.data.referee_fname = res.data.referee_fname
                        this.data.referee_middle_name = res.data.referee_middle_name
                        this.data.referee_lname = res.data.referee_lname
                        this.data.referee_nida_no = res.data.referee_nida_no
                        this.data.referee_phone = res.data.referee_phone
                        this.data.referee_address = res.data.referee_address
                    }
                }catch(error){
                    console.log(error)
                }
            })
        },
    }
}
</script>

<style scoped>
#container-border{
    border-style: solid;
    border-width: 1px;
    border-color: aliceblue
}

.table {
    border-style: solid !important;
    border-width: 1px !important;
    border-color: aliceblue;
}
</style>