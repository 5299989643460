<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card" style="margin-bottom: 5%;">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-10">
                               <span style="font-weight: 500;">Transfer Capital</span>
                            </div>
                            <div class="col-2">
                                <RouterLink to="/bank-account-list">
                                    <button class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                    <pulse-loader 
                        :loading="isLoading" 
                        :color="color"
                        v-if="isLoading" 
                        style="margin-top: 50px; margin-left: 40%;"
                    />
                    <div class="card-body" v-else>
                        <div class="container-fluid " style="border-style: solid; border-width: 1px; border-color: aliceblue;">
                            <div class="row mt-2 justify-content-center">
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Payable Account*</span>
                                        <select class="form-control size mt-2" v-model="data.payableId">
                                            <option v-for="(payable_account, i) in payable_accounts" :key="i" :value="payable_account.id">{{ payable_account.account_name }}</option>
                                        </select>
                                        <div v-if="errors.payableId">
                                            <span class="text-danger">{{ errors.payableId[0] }}*</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Receivable Account*</span>
                                        <select class="form-control size mt-2" v-model="data.receivableId">
                                            <option v-for="(receivable, i) in receivable_accounts" :key="i" :value="receivable.id">{{ receivable.account_name }} - {{ receivable.account_no }} - {{ receivable.banks.bank_name }}</option>
                                        </select>
                                        <div v-if="errors.receivableId">
                                            <span class="text-danger">{{ errors.receivableId[0] }}*</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row justify-content-center">
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Date*</span><br>
                                        <date-picker v-model:value="data.date" value-type="YYYY-MM-DD" />
                                        <div v-if="errors.date">
                                            <span class="text-danger">{{ errors.date[0] }}*</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Amount*</span>
                                        <input type="text" class="form-control size mt-2" v-model="data.amount" />
                                        <div v-if="errors.amount">
                                            <span class="text-danger">{{ errors.amount[0] }}*</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div class="col-2"></div>
                                <div class="col-4" v-if="updating">
                                    <div class="btn btn-info btn-sm disabled" type="button">Submiting...</div>
                                </div>
                                <div class="col-4" v-else>
                                    <div class="btn btn-info btn-sm" @click="transferAmount" type="button">Submit</div>
                                </div>
                                <pulse-loader 
                                    :loading="updating" 
                                    :color="color"
                                    v-if="updating" 
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import 'vue-datepicker-next/index.css';
import Api from '../../services/Api';
import Swal from 'sweetalert2';
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';

export default{
    components: {
        PulseLoader,
        DatePicker
    },

    data(){
        return{
            data: {
              payableId: '',
              receivableId: '',
              date: '',
              amount: '',
              id: this.$route.params.id,
            },

            isLoading: false,
            updating: false,
            color: '#40aac7',
            errors: '',
            payable_accounts: [],
            receivable_accounts: [],
        }
    },

    mounted(){
        this.fetchPayableAccount();
        this.fetchReceivableAccount();
    },

    methods: {
        async fetchPayableAccount(){
            this.isLoading = true
            await Api().get(`bank-accounts/${this.data.id}`)
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.payable_accounts = res.data
                }
            }).catch(error=>{
                if(error.response.status === 422){
                    this.isLoading = false
                    this.errors = error.response.data.errors
                }
            })
        },

        async fetchReceivableAccount(){
            this.isLoading = true
            await Api().get('bank-accounts')
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.receivable_accounts = res.data
                }
            }).catch(error =>{
                if(error){
                    this.isLoading = false
                    console.log('Failed to get receivable account')
                }
            })
        },

        async transferAmount(){
            this.updating = true
            await Api().post('transfer-amount',this.data)
            .then(res =>{
                if(res.status === 200){
                    this.updating = false
                    Swal.fire({
                        title: "Good job!",
                        text: `${res.data.message}`,
                        icon: "success"
                    });
                    this.$router.push('/bank-account-list');
                }else{
                    this.updating = false
                    Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: `${res.data.error}`,
                    });
                }
            }).catch(error =>{
                if(error.response.status === 422){
                    this.updating = false
                    this.errors = error.response.data.errors
                }
            })
        }
    }
}
</script>

<style>
.size{
    height: 34px;
    border-color: rgb(229, 237, 244);
}

.span-style{
    margin-left: 2px;
    font-weight: 500;
}
</style>