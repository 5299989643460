<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card" style="margin-bottom: 5%;">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-10">
                               <span style="font-weight: 500;">Edit Bank Account Information</span>
                            </div>
                            <div class="col-2">
                                <RouterLink to="/bank-account-list">
                                    <button class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                    <pulse-loader 
                        :loading="isLoading" 
                        :color="color"
                        v-if="isLoading" 
                        style="margin-top: 50px; margin-left: 40%;"
                    />
                    <div class="card-body" v-else>
                        <div class="container-fluid " style="border-style: solid; border-width: 1px; border-color: aliceblue;">
                            <div class="row mt-2 justify-content-center">
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Account Name*</span>
                                        <input type="text" class="form-control size mt-2" v-model="data.account_name"/>
                                        <div v-if="errors.account_name">
                                            <span class="text-danger">{{ errors.account_name[0] }}*</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Account Number*</span>
                                        <input type="text" class="form-control size mt-2" v-model="data.account_no"/>
                                        <div v-if="errors.account_no">
                                            <span class="text-danger">{{ errors.account_no[0] }}*</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row justify-content-center">
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Select Bank*</span>
                                        <select class="form-control size mt-2" v-model="data.bankId">
                                            <option v-for="(bank, i) in banks" :key="i" :value="bank.id">{{ bank.bank_name }}</option>
                                        </select>
                                        <div v-if="errors.bankId">
                                            <span class="text-danger">{{ errors.bankId[0] }}*</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Select Bank Branch*</span>
                                        <select class="form-control size mt-2" v-model="data.bank_branchId">
                                            <option v-for="(branch, i) in bank_branches" :key="i" :value="branch.id">{{ branch.branch_name }}</option>
                                        </select>
                                        <div v-if="errors.bank_branchId">
                                            <span class="text-danger">{{ errors.bank_branchId[0] }}*</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row justify-content-center">
                                <div class="col-4">
                                    <div class="form-group">
                                        <span class="span-style">Select Account Type*</span>
                                        <select class="form-control size mt-2" v-model="data.account_type">
                                            <option>Bank</option>
                                            <option>Cash</option>
                                        </select>
                                        <div v-if="errors.account_type">
                                            <span class="text-danger">{{ errors.account_type[0] }}*</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4"></div>
                            </div>

                            <div class="row mb-3">
                                <div class="col-2"></div>
                                <div class="col-4" v-if="updating">
                                    <div class="btn btn-info btn-sm disabled" type="button">Updating...</div>
                                </div>
                                <div class="col-4" v-else>
                                    <div class="btn btn-info btn-sm" @click="updateBankAccount" type="button">Update</div>
                                </div>
                                <pulse-loader 
                                    :loading="updating" 
                                    :color="color"
                                    v-if="updating" 
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import 'vue-datepicker-next/index.css';
import Api from '../../services/Api';
import Swal from 'sweetalert2';

export default{
    components: {
        PulseLoader,
    },

    data(){
        return{
            data: {
              account_name: '',
              account_no: '',
              account_type: '',
              bankId: '',
              bank_branchId: '',
              id: this.$route.params.id,
            },

            isLoading: false,
            updating: false,
            color: '#40aac7',
            errors: '',
            accounts: [],
            banks: [],
            bank_branches: []
        }
    },

    mounted(){
        this.fetchBanks();
        this.fetchBankBranch();
        this.fetchData();
    },

    methods: {
        async fetchData(){
            this.isLoading = true
            await Api().get(`bank-accounts/${this.data.id}`)
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.data.account_name = res.data[0].account_name
                    this.data.account_no = res.data[0].account_no
                    this.data.bankId = res.data[0].bankId
                    this.data.bank_branchId = res.data[0].bank_branchId
                    this.data.account_type = res.data[0].account_type
                }
            }).then(error =>{
                if(error.response.status === 422){
                    this.isLoading = false
                    this.errors = error.response.data.errors
                }
            })
        },

        async fetchBanks(){
            this.isLoading = true
            await Api().get('banks')
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.banks = res.data
                }
            }).catch(error =>{
                if(error.response.status === 422){
                    this.isLoading = false
                    this.errors = error.response.data.errors
                }
            })
        },

        async fetchBankBranch(){
            this.isLoading = true
            await Api().get('bank-branches')
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.bank_branches = res.data
                }
            }).catch(error =>{
                if(error.response.status === 422){
                    this.isLoading = false
                    this.errors = error.response.data.errors
                }
            })
        },

        async updateBankAccount(){
            this.updating = true
            await Api().put(`bank-accounts/${this.data.id}`,this.data)
            .then(res =>{
                if(res.status === 200){
                    this.updating = false
                    Swal.fire({
                    title: "Good job!",
                    text: `${res.data.message}`,
                    icon: "success"
                    });
                    this.$router.push('/bank-account-list')

                }else{
                    this.updating = false
                    Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: `${res.data.message}`,
                    });
                }
            }).catch(error =>{
                if(error.response.status === 422){
                    this.updating = false
                    this.errors= error.response.data.errors
                }
            })
        }
    }
}
</script>

<style>
.size{
    height: 34px;
    border-color: aliceblue;
}

.span-style{
    margin-left: 2px;
    font-weight: 500;
}
</style>